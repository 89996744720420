import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import AppContainer from "./components/AppContainer";
import { Provider } from 'react-redux'
import { store } from './redux'
import { BrowserRouter } from 'react-router-dom'



ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppContainer>
        <App />
      </AppContainer>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
