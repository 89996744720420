export const currentFrame = (index: number) => (
    `${process.env.PUBLIC_URL}/first-screen/first_anim_01${(index).toString().padStart(4, '0')}.jpg`
);

export const currentFrameDir = (index: number, dir: string) => (
    `${process.env.PUBLIC_URL}/${dir}/first_anim_01${(index).toString().padStart(4, '0')}.jpg`
);

export const currentFrameMobile = (index: number) => (
    `${process.env.PUBLIC_URL}/first-screen-mobile/first_anim_01${(index).toString().padStart(4, '0')}.jpg`
);

