import {facebookIco, instagramIco, twitterIco, youtubeIco} from "../../assets/icons";
import { Links } from "./links";

export const menu = [
    [
        {
            id: 1,
            title: 'Shop',
            link: Links.Shop
        }, {
            id: 2,
            title: 'Privacy policy',
            link: Links.Policy
        }, {
            id: 3,
            title: 'Genius one',
            link: Links.GeniusOne
         },
    ], [
        {
            id: 4,
            title: 'Contacts',
            link: Links.Contacts
        }, {
            id: 5,
            title: 'How it works',
            link: Links.HowItWorks
        }, {
            id: 6,
            title: 'Legal',
            link: Links.Legal
        },
    ], [
        {
            id: 7,
            title: 'Press',
            link: Links.Press
        }, {
            id: 8,
            title: 'FAQ',
            link: Links.Faq
        }, {
            id: 9,
            title: 'Reviews',
            link: Links.Reviews
        },
    ], [
        {
            id: 10,
            title: 'Wholesale store',
            link: Links.GeniusOne
        }, {
            id: 11,
            title: 'Store locator',
            link: Links.Locator
        }, {
            id: 12,
            title: 'COVID-19',
            link: Links.Covid
        },
    ]
];

export const social = [
    {
        id: 1,
        icon: facebookIco,
        link: 'https://www.facebook.com/geniuspipe',
        title: 'facebook'
    }, {
        id: 2,
        icon: youtubeIco,
        link: 'https://www.youtube.com/channel/UCfl9EU_DeRihvukKRqZzhow',
        title: 'youtube'
    }, {
        id: 3,
        icon: instagramIco,
        link: 'https://www.instagram.com/genius_tribe/',
        title: 'instagram'
    }, {
        id: 4,
        icon: twitterIco,
        link: 'https://twitter.com/geniuspipe',
        title: 'twitter'
    },
];
