import React, { FC, useContext, useRef } from "react";
import { Canvas } from "./canvas";
import { Description } from "./description";
import { advantages } from "../../models/static-data/static-data";
import { Advantage } from "./advantage";
import { AppContext } from "../AppContainer";
import { Intro } from "./intro";
import { Features } from "./features";

export const FirstScreen: FC = () => {
    const { descriptionBlock, canvasBlock, introBlock } = useContext(AppContext);
    const firstScreen = useRef(null)

    return (
        <div style={{ height: '1000vh', margin: '0 0 -100vh 0', position: "relative" }} ref={firstScreen}>
            <Canvas
                descriptionBlock={descriptionBlock}
                canvasBlock={canvasBlock}
                firstScreen={firstScreen}
            />
            <Intro introBlock={introBlock} />

            <div>
                {advantages.map(advantage => <Advantage {...advantage} key={advantage.id} />)}
            </div>
        </div>
    )
};