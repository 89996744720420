import React from 'react'
import { ItemCircleBg } from '../../assets/images'

import styles from './MobilePopup.module.scss'


const items: any[] = [
    { title: 'Genius Experience', about: 'Magna ullamcorper cursus pharetra at bibendum sapien rhoncus netus. Magna ullamcorper cursus pharetra.' },
    { title: 'Tommy Chong', about: 'Magna ullamcorper cursus pharetra at bibendum sapien rhoncus netus. Magna ullamcorper cursus pharetra.' },
    { title: 'Reviews', about: 'Magna ullamcorper cursus pharetra at bibendum sapien rhoncus netus. Magna ullamcorper cursus pharetra.' },]

interface MobilePopupProps {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setActivePopupItem: React.Dispatch<React.SetStateAction<number>>,
    activePopupItem: number
}

export const MobilePopup = ({ setVisible, visible, activePopupItem, setActivePopupItem }: MobilePopupProps) => {

    const onClickItem = (ind: number, goTo: string) => {


        const scrollTo = document.querySelector(`[data-mark="${goTo}"]`)!.getBoundingClientRect().top + window.pageYOffset
        window.scrollTo(0, scrollTo)
        setActivePopupItem(ind)
        setVisible(false)
        document.body.style.overflowY = 'auto'
    }

    return (
        <div className={`${styles.popup_wrapper} ${visible ? '' : styles.unshown}`}>
            <div className={`${styles.popup} ${visible ? '' : styles.unshown}`}>
                <div className={styles.popup__body}>
                    <p className={styles.popup__title}>Navigation</p>
                    <ul className={styles.popup__items}>
                        {
                            items.map((obj, ind) => {
                                return (
                                    <div key={`${obj.goTo} ${ind}`} className={`${styles.popup__item} ${styles.item}`} onClick={() => onClickItem(ind, obj.title)}>
                                        <div className={styles.item__head}>
                                            <span className={`${styles.item__circle} ${activePopupItem === ind ? styles.active : ''}`} >
                                                <img src={ItemCircleBg} alt="blue circle" />
                                            </span>
                                            <span className={styles.item__title}>{obj.title}</span>
                                        </div>
                                        <p className={styles.item__about}>{obj.about}</p>
                                    </div>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        </div>

    )
}



export default MobilePopup