import { FC } from 'react';
import styles from './Slide.module.scss';

export const Slide: FC<{image: string}> = ({image}) => {
    return (
        <div className={styles.slide}>
            <img src={image} alt=""/>
        </div>
    )
};
