import { gsap } from "gsap";
import { FC, useEffect } from "react";
import { useRef } from "react";
import styles from './Advantage.module.scss';
import { AdvantagesProps } from "../../../models/advantagesProps";
import { Title } from "../../common/title";
import { ScrollTriggerIds } from "../../../models/scrollTriggerIds";

export const Advantage: FC<AdvantagesProps> = ({ texts, title, end }) => {
    const block = useRef(null);

    useEffect(() => {
        const blockTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.AdvantageBlockTrigger,
                trigger: block.current,
                start: "top 80%",
                end: "+=800",
                scrub: 1
            }
        });

        blockTimeline.from(block.current, { y: 150, opacity: 0 });
        blockTimeline.to(block.current, { y: -150, opacity: 0 });


    }, []);

    return (
        <div className={`container ${styles.wrapper}`} ref={block}>
            <div className={styles.advantage}>
                <Title>{title}</Title>
                {
                    texts.map((text, i) => (
                        <p className={styles.text} key={i}>{text}</p>
                    ))
                }
            </div>
        </div>
    )
}
