export const lines = [
    {
        count: 1,
        text: 'One text line'
    }, {
        count: 2,
        text: 'Two text lines'
    },
]

export const fonts = [
    'Georgia',
    'Eduardian',
    'Script MT Bold'
]