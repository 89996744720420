import React, {useContext} from "react";
import styles from './Modal.module.scss';
import {ImageUpload} from "../image-upload";
import {Close} from "../close";
import {AppContext} from "../../AppContainer";

export const Modal = () => {
    const {closeModal} = useContext(AppContext);

    return (
        <div
            className={styles.background}
            onClick={(event) => {
                if(event.target === event.currentTarget) {
                    closeModal()
                }
            }}
        >
            <div className={styles.block}>
                <Close onClick={closeModal} className={styles.close} />
                <ImageUpload />
            </div>
        </div>
    )
};
