import styles from './CustomPipeSlider.module.scss';
import { sliders } from '../../../models/static-data/sliders';
import {useContext} from "react";
import {AppContext} from "../../AppContainer";

export const CustomPipeSlider = () => {
    const {setActiveSlider} = useContext(AppContext);

    const chooseSlider = (id: number) => {
        setActiveSlider(id);
    };

    return (
        <div className={styles.slider}>
            {
                sliders.map(slide => (
                    <button key={slide.id} className={styles.item} onClick={() => chooseSlider(slide.id)}>
                        <img src={slide.icon} alt=""/>
                    </button>
                ))
            }

            <button className={styles.item} onClick={() => chooseSlider(-1)}>
                None
            </button>
        </div>
    )
};
