import {
    candy,
    candySmall,
    cats,
    catsSmall,
    cockpaw,
    cockpawSmall,
    frivolous,
    frivolousSmall,
    hanukkah,
    hanukkahSmall,
    herb,
    herbSmall,
    holiday,
    holidaySmall,
    hypnotic,
    hypnoticSmall,
    independence,
    independenceSmall,
    kwanzaa,
    kwanzaaSmall,
    liberty,
    libertySmall,
    mad,
    madSmall,
    mexico,
    mexicoSmall,
    monaLisa,
    monaLisaSmall,
    night,
    nightSmall,
    passion,
    passionSmall, portrait, portraitSmall,
    psychedelic,
    psychedelicSmall, queen, queenSmall, rainbow,
    rainbowSmall,
    roger,
    rogerSmall,
    roller,
    rollerCherry,
    rollerCherrySmall,
    rollerSmall, samsara, samsaraSmall, starryNight, starryNightSmall, stunt, stuntSmall,
    wolf,
    wolfSmall
} from "../../assets/images";
import { limitedPipe } from "../settingsPipe";

const BUY_URL = 'https://geniuspipe.com/cart/add?id='

export const designPipes = [
    {
        id: limitedPipe.Cockpaw,
        image: `url(${cockpawSmall})`,
        pipe: cockpaw,
        link: 'https://geniuspipe.com/collections/genius-limited/products/cockpaw-by-sean-dietrich'
    }, {
        id: limitedPipe.Frivolous,
        image: `url(${frivolousSmall})`,
        pipe: frivolous,
        link: `${BUY_URL}25972366537`
    }, {
        id: limitedPipe.Holiday,
        image: `url(${holidaySmall})`,
        pipe: holiday,
        link: `${BUY_URL}36487108722760`
    }, {
        id: limitedPipe.Candy,
        image: `url(${candySmall})`,
        pipe: candy,
        link: 'https://geniuspipe.com/collections/genius-limited/products/holiday-pipes'
    }, {
        id: limitedPipe.Hanukkah,
        image: `url(${hanukkahSmall})`,
        pipe: hanukkah,
        link: 'https://geniuspipe.com/collections/genius-limited/products/holiday-pipes'
    }, {
        id: limitedPipe.Herb,
        image: `url(${herbSmall})`,
        pipe: herb,
        link: 'https://geniuspipe.com/collections/genius-limited/products/herb-branded-genius-pipe'

    }, {
        id: limitedPipe.Kwanzaa,
        image: `url(${kwanzaaSmall})`,
        pipe: kwanzaa,
        link: 'https://geniuspipe.com/collections/genius-limited/products/holiday-pipes'
    }, {
        id: limitedPipe.Mexico,
        image: `url(${mexicoSmall})`,
        pipe: mexico,
        link: 'https://geniuspipe.com/collections/genius-limited/products/bandera-de-mexico'
    }, {
        id: limitedPipe.Cats,
        image: `url(${catsSmall})`,
        pipe: cats,
        link: 'https://geniuspipe.com/collections/genius-limited/products/genius-cats'
    }, {
        id: limitedPipe.Roger,
        image: `url(${rogerSmall})`,
        pipe: roger,
        link: 'https://geniuspipe.com/collections/genius-limited/products/jolly-roger'
    }, {
        id: limitedPipe.Wolf,
        image: `url(${wolfSmall})`,
        pipe: wolf,
        link: 'https://geniuspipe.com/collections/genius-limited/products/wolf'
    }, {
        id: limitedPipe.Roller,
        image: `url(${rollerSmall})`,
        pipe: roller,
        link: 'https://geniuspipe.com/collections/genius-limited/products/high-roller'
    }, {
        id: limitedPipe.RollerCherry,
        image: `url(${rollerCherrySmall})`,
        pipe: rollerCherry,
        link: 'https://geniuspipe.com/collections/genius-limited/products/high-roller'
    }, {
        id: limitedPipe.Hypnotic,
        image: `url(${hypnoticSmall})`,
        pipe: hypnotic,
        link: 'https://geniuspipe.com/collections/genius-limited/products/hypnotic-genius'
    }, {
        id: limitedPipe.Independence,
        image: `url(${independenceSmall})`,
        pipe: independence,
        link: 'https://geniuspipe.com/collections/genius-limited/products/independence-genius'
    }, {
        id: limitedPipe.Liberty,
        image: `url(${libertySmall})`,
        pipe: liberty,
        link: 'https://geniuspipe.com/collections/genius-limited/products/liberty'
    }, {
        id: limitedPipe.Mad,
        image: `url(${madSmall})`,
        pipe: mad,
        link: 'https://geniuspipe.com/collections/genius-limited/products/made-in-wonderland'
    }, {
        id: limitedPipe.MonaLisa,
        image: `url(${monaLisaSmall})`,
        pipe: monaLisa,
        link: 'https://geniuspipe.com/collections/genius-limited/products/mona-lisa'
    }, {
        id: limitedPipe.Night,
        image: `url(${nightSmall})`,
        pipe: night,
        link: 'https://geniuspipe.com/collections/genius-limited/products/night-over-exon'
    }, {
        id: limitedPipe.Passion,
        image: `url(${passionSmall})`,
        pipe: passion,
        link: 'https://geniuspipe.com/collections/genius-limited/products/passion'
    }, {
        id: limitedPipe.Psychedelic,
        image: `url(${psychedelicSmall})`,
        pipe: psychedelic,
        link: 'https://geniuspipe.com/collections/genius-limited/products/psychedelic-genius'
    }, {
        id: limitedPipe.Rainbow,
        image: `url(${rainbowSmall})`,
        pipe: rainbow,
        link: 'https://geniuspipe.com/collections/genius-limited/products/genius-pipe-rainbow'
    }, {
        id: limitedPipe.Queen,
        image: `url(${queenSmall})`,
        pipe: queen,
        link: 'https://geniuspipe.com/collections/genius-limited/products/red-queen-by-sean-dietrich'
    }, {
        id: limitedPipe.Samsara,
        image: `url(${samsaraSmall})`,
        pipe: samsara,
        link: 'https://geniuspipe.com/collections/genius-limited/products/sa-sara'
    }, {
        id: limitedPipe.Portrait,
        image: `url(${portraitSmall})`,
        pipe: portrait,
        link: 'https://geniuspipe.com/collections/genius-limited/products/self-portrait-as-rrose-selavy'
    }, {
        id: limitedPipe.StarryNight,
        image: `url(${starryNightSmall})`,
        pipe: starryNight,
        link: 'https://geniuspipe.com/collections/genius-limited/products/starry-night'
    }, {
        id: limitedPipe.Stunt,
        image: `url(${stuntSmall})`,
        pipe: stunt,
        link: 'https://geniuspipe.com/collections/genius-limited/products/genius-stunt-lifestyle'
    }
];
