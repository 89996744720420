import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk"


import { FixedCustomPipeReucer, CustomPipeExampleReducer, cart, catalog, shop } from './reducers'

//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
    FixedCustomPipe: FixedCustomPipeReucer,
    CustomPipeExample: CustomPipeExampleReducer,
    cart: cart,
    catalog: catalog,
    shop: shop
})

export type RootState = ReturnType<typeof rootReducer>

const store = createStore(rootReducer,
    composeEnhancers(applyMiddleware(thunk))
)

// @ts-ignore
window.state = store.getState

export default store


// import { composeWithDevTools } from 'redux-devtools-extension';
// additional comment