import React, { FC, useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { currentFrameDir } from "../../../utils/utils";
import styles from './Canvas.module.scss';
import { ScrollTriggerIds } from "../../../models/scrollTriggerIds";
import { CanvasProps } from "../../../models/canvasProps";
import { AppContext } from "../../AppContainer";

gsap.registerPlugin(ScrollTrigger);

export const Canvas: FC<CanvasProps> = ({
    screen,
    countFrames,
    dir,
    className,
    appearing = false,
    disappearing = false,
    start = ''
}) => {
    const canvas = useRef<HTMLCanvasElement>(null);
    const { isMobile, isIPad } = useContext(AppContext);

    const size = function () {
        if (isIPad && !isMobile) return 700;
        return window.innerWidth > window.innerHeight ? window.innerHeight : window.innerWidth
    }();

    useEffect(() => {
        if (canvas.current) {
            //render canvas with images
            const context = canvas.current.getContext("2d");
            canvas.current.width = size;
            canvas.current.height = size;

            const images: Array<HTMLImageElement> = [];

            const frames = { frame: 0 };

            for (let i = 0; i < countFrames; i++) {
                const img = new Image();
                img.src = currentFrameDir(i, dir);
                images.push(img);
            }

            const render = () => {
                if (context && canvas.current) {
                    context.clearRect(0, 0, canvas.current.width, canvas.current.height);

                    context.drawImage(
                        images[frames.frame],
                        0,
                        0,
                        size,
                        size
                    );
                }
            }

            gsap.to(frames, {
                ease: 'slow',
                frame: countFrames - 1,
                // @ts-ignore
                // mistake of type in the library
                snap: 'frame',
                scrollTrigger: {
                    id: ScrollTriggerIds.CanvasCommonRenderTrigger,
                    trigger: screen.current,
                    start: `top top${start}`,
                    end: `bottom bottom`,
                    scrub: 0.5,
                },
                onUpdate: render,
            });

            images[0].onload = render;
            appearing && appearingCanvas();
            disappearing && disappearingCanvas();


        }
    }, []);

    const appearingCanvas = () => {
        const appearCanvasTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.CanvasCommonAppearTrigger,
                trigger: screen.current,
                start: "top top",
                end: "center center-=200",
                scrub: 0.5,
            }
        });

        appearCanvasTimeline.from(canvas.current, { opacity: 0, duration: 0.5 })
    };

    const disappearingCanvas = () => {
        const disappearingCanvasTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.CanvasCommonDisappearTrigger,
                trigger: screen.current,
                start: "bottom bottom+=1000",
                scrub: 0.5,
            }
        });

        disappearingCanvasTimeline.to(canvas.current, { opacity: 0 });
    };

    return (
        <div className={`${styles.canvas} ${className ? className : ''}`}>
            <canvas ref={canvas} />
        </div>
    )
};
