import React, { useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styles from './Reviews.module.scss';
import { reviews } from "../../assets/images";
import { Title } from "../common/title";
import { Link } from "../common/link";
import { Links } from "../../models/static-data/links";
import { ScrollTriggerIds } from "../../models/scrollTriggerIds";
import "keen-slider/keen-slider.min.css"
import { ReviewBlocks } from "./review-blocks";
import { AppContext } from "../AppContainer";
import { ReviewSlider } from "./review-slider";

gsap.registerPlugin(ScrollTrigger);

export const Reviews = () => {
    const block = useRef(null);
    const first = useRef(null);
    const second = useRef(null);
    const third = useRef(null);
    const fourth = useRef(null);
    const { isMobile } = useContext(AppContext);

    useEffect(() => {
        const firstTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.ReviewFirstBlockTrigger,
                trigger: block.current,
                start: "top top",
                end: `top top-=${window.innerHeight}`,
                scrub: 1,
            },
        });

        firstTimeline.to(first.current, { y: 100, opacity: 0 })

        const secondTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.ReviewSecondBlockTrigger,
                trigger: block.current,
                start: `top top-=${window.innerHeight}`,
                end: `center center-=${window.innerHeight}`,
                scrub: 1,
            }
        });

        secondTimeline.from(second.current, { y: -100, opacity: 0 });
        secondTimeline.to(second.current, { y: 100, opacity: 0 });

        const thirdTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.ReviewThirdBlockTrigger,
                trigger: block.current,
                start: `center center-=${window.innerHeight}`,
                scrub: 1,
            }
        });

        thirdTimeline.from(third.current, { y: -100, opacity: 0 });

        if (!isMobile) {
            thirdTimeline.from(fourth.current, { y: 20, opacity: 0 });
            thirdTimeline.to(fourth.current, { y: 0, opacity: 1, animationTimingFunction: 'easy-in' });
        }


    }, []);

    const height = isMobile ? '150vh' : '500vh';

    return (
        <div
            style={{ height, position: "relative" }}
            ref={block}
        >
            <div className={styles.back}>
                {!isMobile && <img src={reviews} alt="" className={styles.img} />}

                <div className={`${styles.wrapper}`}>
                    <div data-mark="Reviews" className={styles.content}>
                        <Title className={styles.title}>Reviews</Title>

                        {!isMobile
                            ? <ReviewBlocks first={first} second={second} third={third} />
                            : <ReviewSlider />
                        }

                        <div ref={fourth}>
                            <Link
                                text={"see more reviews"}
                                link={Links.Reviews}
                                mode={"white"}
                                className={styles.link}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
};
