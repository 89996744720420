import React from "react";
import styles from './Slider.module.scss';
import {arrowLeft} from "../../../assets/icons";

export const ArrowLeft = (props: any) => {
    return (
        <img
            onClick={props.onClick}
            className={`${styles.arrow} ${styles.left}`}
            src={arrowLeft}
            alt=""
        />
    )
};
