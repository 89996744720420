import { useContext, useEffect, useState } from "react";
import { Navigation } from "../../models/navigation";
import { nav, leftNavigation } from "../../models/static-data/static-data";
import styles from './Header.module.scss';
import { Logo } from "../common/logo";
import { HeaderScroll } from "./header-scroll";
import { AppContext } from "../AppContainer";
import axios from 'axios'
import { setCartData } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { Link } from "react-router-dom";



export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [cartDataState, setCartDataState] = useState({});
    const { isHeaderWhite, isIPad } = useContext(AppContext);

    const dispatch = useDispatch()
    const cartData = useSelector(({ cart }: RootState) => cart)

    // @ts-ignore

    useEffect(() => {
        dispatch(setCartData(cartDataState))
    }, [cartDataState])

    useEffect(() => {
        function getCartData(data: any) {
            dispatch(setCartData({ totalCount: data.item_count, totalPrice: data.total_price, items: data.items }))

        }

        // @ts-ignore
        window.getCartData = getCartData

        function test() {
            let script = document.createElement('script');
            script.src = 'https://geniuspipe.com/cart.json?callback=getCartData'
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        test()



        const handleScroll = () => {
            if (window.scrollY > window.innerHeight) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        }

        if (!isIPad) {
            document.addEventListener('scroll', handleScroll);
        }

        if (isIPad) {
            setIsOpen(true);
        }

        return () => {
            if (!isIPad) {
                document.removeEventListener('scroll', handleScroll);
            }
        }
    }, []);

    return !isOpen
        ? (
            <header
                className={`${styles.header} ${isHeaderWhite ? styles.white : ''}`}
            >
                <div className={styles.right}>
                    <Logo width={93} height={35} />
                    <nav>
                        <ul className={styles.nav}>
                            {
                                nav.map(({ link, id, title, navLink }: Navigation) => (
                                    <li key={id}>
                                        {navLink ? <Link to={navLink}>{title}</Link> : <a
                                            href={link}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {title}
                                        </a>}

                                    </li>
                                ))
                            }
                        </ul>
                    </nav>
                </div>
                <ul className={styles.menu}>
                    {
                        leftNavigation.map(({ link, id, icon, title, lambaCount }) => (
                            <li key={id} style={{ position: 'relative' }}>
                                <a
                                    href={link}
                                    className={styles.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={icon} alt={title} />
                                    {title}
                                </a>
                                {lambaCount === true && cartData.totalCount > 0 && <div className={styles.lambaCount}>{cartData.totalCount}</div>}

                            </li>
                        ))
                    }
                </ul>
            </header>
        ) : (
            <HeaderScroll setIsOpen={setIsOpen} />
        )
};
