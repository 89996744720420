import { Navigation } from "../navigation";
import { loginIco, markerIco, storeIco, cartIco } from "../../assets/icons";
import { SettingsPipe } from "../settingsPipe";
import { Links } from "./links";

export const nav: Navigation[] = [
    {
        id: 1,
        title: 'Shop',
        link: Links.Shop
    }, {
        id: 2,
        title: 'How it works',
        link: Links.HowItWorks
    }, {
        id: 3,
        title: 'FAQ',
        link: Links.Faq
    }, {
        id: 4,
        title: 'Karma',
        link: Links.Karma
    }
];

export const leftNavigation: Navigation[] = [
    {
        id: 1,
        title: 'Wholesale store',
        link: Links.WholesaleStore,
        icon: storeIco
    }, {
        id: 2,
        title: 'Store locator',
        link: Links.Locator,
        icon: markerIco
    }, {
        id: 3,
        title: 'Cart',
        link: Links.Cart,
        icon: cartIco,
        lambaCount: true
    }, {
        id: 4,
        title: 'Login',
        link: Links.Login,
        icon: loginIco
    },
    //Not yet implemented
    /*{
        id: 4,
        title: '99 999.00 $',
        link: Links.Cart,
        icon: packageIco
    },*/
];

export const advantages = [
    {
        id: 1,
        end: 1000,
        title: 'High Style',
        texts: [
            'Sexy. Modern. Discreet'
        ]
    }, {
        id: 2,
        end: 1000,
        title: 'Weedsaver Slider™',
        texts: [
            'Slide closed to extinguish flame. Conserve flower. Save money. Pack bowl and slide closed for travel. Slide open to smoke.'
        ]
    },
];

export const settingsCustomPipe = [
    {
        id: SettingsPipe.Color,
        text: '1. Base color',
    }, {
        id: SettingsPipe.Slider,
        text: '2. Slider',
    }, {
        id: SettingsPipe.Design,
        text: '3. Limited edition',
    }, {
        id: SettingsPipe.Engraving,
        text: '4. Engraving',
    },
]
