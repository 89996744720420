import { tribe1, tribe2, tribe3, tribe4 } from '../../assets/images';
import { Links } from '../../models/static-data/links';
import { Button } from '../common/button';
import { Title } from '../common/title';
import styles from './Tribe.module.scss';

export const Tribe = () => {
    return (
        <div className={styles.tribe}>

            <div className={styles.collage}>
                <img src={tribe1} alt=""/>
                <img src={tribe2} alt=""/>
                <img src={tribe3} alt=""/>
                <img src={tribe4} alt=""/>
            </div>

            <div className={styles.content}>

                <Title className={styles.title}>Genius Tribe</Title>
                <p className={styles.text}>
                    Together we are ONE. In the new economy of abundance, we
                    should all be working toward common goal. Join the movement
                    and help us grow by contributing your time, your energy,
                    your GENIUS.
                </p>
                <p className={styles.text}>
                    Being part of the movement creates opportunities to grow
                    for every ONE. This is reflected in Genius karma.
                </p>

                <Button
                    className={styles.button}
                    text={'Learn more'}
                    mode="transparent"
                    link={Links.Karma}
                    type={'link'}
                />
            </div>
        </div>
    )
};
