import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { ColorPipe, kindPipe, limitedPipe } from "../models/settingsPipe";
import { currentFrame, currentFrameMobile } from "../utils/utils";
import { Files } from "../models/files";
import { useMediaQuery } from "react-responsive";
import { iPadProQuery, mobileQuery, notebookQuery } from "../media-queries/MediaQueries";

export const AppContext = React.createContext<{
    descriptionBlock: MutableRefObject<null | HTMLParagraphElement>;
    featureBlock: MutableRefObject<null | HTMLDivElement>;
    canvasBlock: MutableRefObject<null | HTMLCanvasElement>;
    introBlock: MutableRefObject<null | HTMLDivElement>;
    isHeaderWhite: boolean;
    setIsHeaderWhite: React.Dispatch<React.SetStateAction<boolean>>;
    activeTypePipe: kindPipe;
    setActiveTypePipe: React.Dispatch<React.SetStateAction<kindPipe>>;
    activeColorPipe: ColorPipe;
    setActiveColorPipe: React.Dispatch<React.SetStateAction<ColorPipe>>;
    activeDesignPipe: limitedPipe;
    setActiveDesignPipe: React.Dispatch<React.SetStateAction<limitedPipe>>;
    modal: boolean;
    openModal: () => void;
    closeModal: () => void;
    fileImg: Files[] | Array<{}>;
    setFileImg: React.Dispatch<React.SetStateAction<Files[] | Array<{}>>>;
    isApplyImg: boolean;
    setIsApplyImg: React.Dispatch<React.SetStateAction<boolean>>;
    chooseDesignPipe: (id: number) => void;
    activeSlider: number;
    setActiveSlider: React.Dispatch<React.SetStateAction<number>>;
    countEngravingLine: number;
    setCountEngravingLine: React.Dispatch<React.SetStateAction<number>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    isOrientationChanged: boolean;
    setIsOrientationChanged: React.Dispatch<React.SetStateAction<boolean>>;
    images: Array<HTMLImageElement>;
    isMobile: boolean;
    isIPad: boolean;
    isOpenMobileMenu: boolean;
    isNotebook: boolean;
    setIsOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
    // bem
    visibleMenuPopup: boolean;
    setVisibleMenuPopup: React.Dispatch<React.SetStateAction<boolean>>;
    activePopupItem: number;
    setActivePopupItem: React.Dispatch<React.SetStateAction<number>>;

    imageToggleFixedPipe: MutableRefObject<null | HTMLInputElement>

    // bem
}>({
    descriptionBlock: { current: null },
    canvasBlock: { current: null },
    introBlock: { current: null },
    isHeaderWhite: false,
    setIsHeaderWhite: () => [],
    activeTypePipe: kindPipe.Standard,
    setActiveTypePipe: () => [],
    activeColorPipe: ColorPipe.Silver,
    setActiveColorPipe: () => [],
    activeDesignPipe: 0,
    setActiveDesignPipe: () => [],
    modal: false,
    openModal: () => {
    },
    closeModal: () => {
    },
    fileImg: [{ path: '', preview: '' }],
    setFileImg: () => [],
    isApplyImg: false,
    setIsApplyImg: () => [],
    chooseDesignPipe: () => {
    },
    activeSlider: 1,
    setActiveSlider: () => [],
    countEngravingLine: 1,
    setCountEngravingLine: () => [],
    featureBlock: { current: null },
    isLoading: true,
    setIsLoading: () => [],
    isOrientationChanged: false,
    setIsOrientationChanged: () => [],
    images: [],
    isMobile: false,
    isIPad: false,
    isOpenMobileMenu: false,
    isNotebook: false,
    setIsOpenMobileMenu: () => [],
    // bem
    visibleMenuPopup: false,
    setVisibleMenuPopup: () => { },
    activePopupItem: 0,
    setActivePopupItem: () => { },

    imageToggleFixedPipe: { current: null }
    // bem
});

/*
count images of first screen 225 - 1st;
226 - 436 - 2nd;
437 - 607 - 3rd
 */
const COUNT_FRAMES = 437;

function AppContainer(props: any = null) {
    const isMobile = useMediaQuery({ maxWidth: mobileQuery });
    const isIPad = useMediaQuery({ maxWidth: iPadProQuery });
    const isNotebook = useMediaQuery({ maxWidth: notebookQuery });

    const [isLoading, setIsLoading] = useState(true);
    const [isOrientationChanged, setIsOrientationChanged] = useState(false);
    const [modal, setModal] = useState(false);
    const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

    // bem
    const [visibleMenuPopup, setVisibleMenuPopup] = useState(false)
    const [activePopupItem, setActivePopupItem] = useState(0)

    // bem

    const images: Array<HTMLImageElement> = [];

    const load = async () => {
        for (let i = 0; i < COUNT_FRAMES; i++) {
            const img = new Image();
            img.src = isMobile ? currentFrameMobile(i) : currentFrame(i);
            images.push(img);

            if (i === COUNT_FRAMES - 1) {
                images[COUNT_FRAMES - 1].addEventListener('load', () => setIsLoading(false));
            }
        }
    }

    useEffect(() => {
        load().then(() => console.log('loaded'));
    }, []);

    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);

    //first screen
    const descriptionBlock = useRef<HTMLDivElement>(null);
    const featureBlock = useRef<HTMLDivElement>(null);
    const canvasBlock = useRef<HTMLCanvasElement>(null);
    const introBlock = useRef<HTMLParagraphElement>(null);
    const imageToggleFixedPipe = useRef<HTMLInputElement>(null);
    const [isHeaderWhite, setIsHeaderWhite] = useState(false);

    //custom pipe
    const [activeTypePipe, setActiveTypePipe] = useState(kindPipe.Standard);
    const [activeColorPipe, setActiveColorPipe] = useState(ColorPipe.Silver);
    const [activeDesignPipe, setActiveDesignPipe] = useState(1);
    const [activeSlider, setActiveSlider] = useState(3);

    const [fileImg, setFileImg] = useState<Files[] | Array<{}>>([{}]);
    const [isApplyImg, setIsApplyImg] = useState(false);

    const [countEngravingLine, setCountEngravingLine] = useState(1);

    const chooseDesignPipe = (id: number) => {
        setActiveDesignPipe(id);
        setIsApplyImg(false);
    }

    return (
        <AppContext.Provider value={{
            descriptionBlock,
            canvasBlock,
            introBlock,
            isHeaderWhite,
            setIsHeaderWhite,
            activeTypePipe,
            setActiveTypePipe,
            activeColorPipe,
            setActiveColorPipe,
            activeDesignPipe,
            setActiveDesignPipe,
            modal,
            openModal,
            closeModal,
            fileImg,
            setFileImg,
            isApplyImg,
            setIsApplyImg,
            chooseDesignPipe,
            activeSlider,
            setActiveSlider,
            countEngravingLine,
            setCountEngravingLine,
            featureBlock,
            isLoading,
            setIsLoading,
            isOrientationChanged,
            setIsOrientationChanged,
            images,
            isMobile,
            isIPad,
            isNotebook,
            isOpenMobileMenu,
            setIsOpenMobileMenu,

            // bem 
            visibleMenuPopup,
            setVisibleMenuPopup,
            activePopupItem,
            setActivePopupItem,
            imageToggleFixedPipe
        }}>

            {props.children}
        </AppContext.Provider>
    )
}

export default AppContainer;
