import React, { useContext, useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styles from './VideoBlock.module.scss';
import { mutedIco, quotesIco, unmuteIco, play, pause } from "../../assets/icons";
import { Title } from "../common/title";
import { AppContext } from "../AppContainer";
import { CSSProperties } from "react";
import { ScrollTriggerIds } from "../../models/scrollTriggerIds";
import { Link } from "../common/link";

gsap.registerPlugin(ScrollTrigger);

export const VideoBlock = () => {
    const video = useRef<HTMLVideoElement>(null);
    const block = useRef(null);
    const text = useRef(null);
    const firstText = useRef(null);
    const { setIsHeaderWhite, isMobile, isIPad } = useContext(AppContext);
    const [isMutedVideo, setIsMutedVideo] = useState(true);
    const [isVideoEnable, setIsVideoEnable] = useState(false);
    const [isVideoPaused, setPause] = useState(false);

    const maskPosition = function () {
        const position = !isMobile ? `85% -20%` : '100% 25%'
        const size = !isMobile ? `auto 150vh` : `auto 50vh`

        if (isIPad && !isMobile) {
            return {
                'WebkitMaskPosition': '120% -25%',
                'WebkitMaskSize': 'auto 120vh'
            }
        }

        return {
            'WebkitMaskPosition': position,
            'WebkitMaskSize': size
        }
    }();

    const maskAnimatePosition = function () {
        const position = `50% 15%`
        const size = !isMobile ? `auto 1500vh` : `auto 900vh`

        return {
            'WebkitMaskPosition': position,
            'WebkitMaskSize': size
        }
    }();

    const watchVideo = () => {
        if (video.current === null || video.current === undefined) {
            return;
        }

        video.current.currentTime = 0;
        video.current.play();
        setIsVideoEnable(!isVideoEnable);
    }


    useEffect(() => {

        if (video.current) {
            const blockTimeline = gsap.timeline({
                scrollTrigger: {
                    id: ScrollTriggerIds.VideoBlockTrigger,
                    trigger: block.current,
                    start: "+=1500",
                    end: "bottom bottom+=1000",
                    scrub: 0.5,
                }
            });

            gsap.timeline({
                scrollTrigger: {
                    id: 'trigger',
                    trigger: block.current,
                    start: 'top top',
                    onEnter: () => setIsHeaderWhite(true),
                    onLeave: () => { setIsHeaderWhite(false); setIsVideoEnable(false) },
                    onEnterBack: () => setIsHeaderWhite(true),
                    onLeaveBack: () => { setIsHeaderWhite(false); setIsVideoEnable(false) },
                    onUpdate: ({ progress }) => {
                        const valueOfStartVideo = 0.5877260558343009;
                        const valueOfFinishVideo = 0.39863997190661804;

                        if (progress <= valueOfStartVideo && progress >= valueOfFinishVideo) {
                            setIsHeaderWhite(false);
                        } else {
                            setIsHeaderWhite(true);
                        }
                    }
                }
            })

            gsap.timeline({
                scrollTrigger: {
                    id: ScrollTriggerIds.VideoHeaderTrigger,
                    trigger: video.current,
                    start: "top top",
                    scrub: 1,
                }
            });

            blockTimeline.to(video.current, {
                delay: 1,
                duration: 3,
                ...maskAnimatePosition
            });

            blockTimeline.to(video.current, {
                duration: 2,
                ...maskPosition
            });

            const firstTextTimeLine = gsap.timeline({
                scrollTrigger: {
                    id: ScrollTriggerIds.VideoFirstTextTrigger,
                    trigger: block.current,
                    start: `top top+=200vh`,
                    end: `center center`,
                    scrub: 0.5,
                }
            })

            firstTextTimeLine.from(firstText.current, { opacity: 0, y: 50, animationTimingFunction: 'easy-in' });
            firstTextTimeLine.to(firstText.current, { opacity: 0, animationTimingFunction: 'easy-in' });

            const textTimeline = gsap.timeline({
                scrollTrigger: {
                    id: ScrollTriggerIds.VideoSecondTextTrigger,
                    trigger: block.current,
                    start: `bottom bottom+=1500`,
                    end: `bottom bottom+=100`,
                    scrub: 0.5,
                }
            });

            textTimeline.from(text.current, { opacity: 0, y: 50, animationTimingFunction: 'easy-in', duration: 1 });
            textTimeline.to(text.current, { opacity: 1, animationTimingFunction: 'easy-in', duration: 1 });
        }


    }, []);

    return (
        <div ref={block} style={{
            position: 'relative',
            height: '800vh',
            backgroundColor: 'white',
        }}>
            <div className={styles.block}>
                <button className={styles.button} onClick={() => setIsMutedVideo(current => !current)}>
                    <img src={isMutedVideo ? mutedIco : unmuteIco} alt="" />
                </button>
                <button hidden={!isVideoEnable} className={styles.closeButton} onClick={() => setIsVideoEnable(current => !current)}>
                    X
                </button>
                {isVideoPaused ?
                    (<button hidden={!isVideoEnable} className={styles.playButton} onClick={() => { video.current?.play(); setPause(false) }}>
                        <img src={play} alt="Play" />
                    </button>) :
                    (<button hidden={!isVideoEnable} className={styles.pauseButton} onClick={() => { video.current?.pause(); setPause(true) }}>
                        <img src={pause} alt="Pause" />
                    </button>
                    )
                }

                <div data-mark="Tommy Chong" className="container">
                    <div
                        ref={firstText}
                        className={styles.wrapper}
                        style={{ background: `url(${quotesIco}) left 70% no-repeat` }}
                    >
                        <Title className={styles.title}>Tommy Chong recommends</Title>
                        <p className={styles.text}>
                            I`ve tried every pipe bomb, vaporizer you can imagine. But
                            this Genius pipe really blows my mind!
                        </p>
                    </div>
                    <div hidden={isVideoEnable} ref={text} className={styles.wrapper} style={{ zIndex: 1 }}>
                        <Title className={styles.title}>Tommy Chong recommends</Title>
                        <Link
                            type="button"
                            text={"Watch video"}
                            onClick={watchVideo}
                            className={styles.link}
                        />
                    </div>
                </div>

                <video playsInline
                    className={styles.video}
                    loop ref={video}
                    autoPlay
                    muted={isMutedVideo}
                    style={(isVideoEnable) ? maskAnimatePosition as CSSProperties : maskPosition as CSSProperties}
                >
                    <source src='https://s3-eu-central-1.amazonaws.com/cdn.genius.uno/chong.mp4' type="video/mp4" />
                </video>
            </div>
        </div>
    )
};