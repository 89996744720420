import styles from './Circle.module.scss';
import React, {FC} from 'react';
import {CircleProps} from "../../../models/CircleProps";

export const Circle: FC<CircleProps> = ({
                                            isActive,
                                            name,
                                            onClick,
                                            background,
                                            id
                                        }) => {
    return (
        <div
            className={`${styles.circle} ${isActive ? styles.active : ''}`}
            onClick={() => onClick(id)}
        >
            <div className={styles.wrapper}>
                <button
                    className={styles.color}
                    style={{background: `${background} center`}}
                />
            </div>
            {name && <p>{name}</p>}
        </div>
    )
};
