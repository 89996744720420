import React, { FC } from "react";
import styles from './Link.module.scss';
import { arrowIco } from "../../../assets/icons";
import img from "*.jpg";
import { LinkProps } from "../../../models/linkProps";

export const Link: FC<LinkProps> = ({
    type = '',
    text = '',
    className = '',
    mode,
    link,
    onClick,
    margin
}) => {
    return type === 'button' ? (
        <button style={{ marginTop: margin }}
            onClick={onClick}
            className={`${styles.link} ${className ? className : ''} ${mode ? styles[mode] : ''}`}
        >
            {text} <img src={arrowIco} alt="follow link" />
        </button>
    ) : (
        <a style={{ marginTop: margin }}
            href={link}
            target="_blank"
            rel="noreferrer"
            className={`${styles.link} ${className ? className : ''} ${mode ? styles[mode] : ''}`}
        >
            {text} <img src={arrowIco} alt="follow link" />
        </a>
    )
};
