import { Button } from "../../common/button";
import { Logo } from "../../common/logo";
import styles from './HeaderScroll.module.scss';
import React, { FC, useContext } from "react";
import { AppContext } from "../../AppContainer";
import { Links } from "../../../models/static-data/links";
import { HeaderMobile } from "../header-mobile";

export const HeaderScroll: FC<{ setIsOpen: React.Dispatch<React.SetStateAction<boolean>> }> = ({ setIsOpen }) => {
    const { isHeaderWhite, isIPad, isOpenMobileMenu, setIsOpenMobileMenu } = useContext(AppContext);

    const openMenu = () => {
        if (!isIPad) {
            setIsOpen(false);
            return;
        }

        setIsOpenMobileMenu(true);
    }

    return (
        <header className={`${styles.header} ${isHeaderWhite ? styles.white : ''}`}>
            <Logo width={93} height={35} />
            <div className={styles.controls}>

                <Button
                    text="Buy"
                    mode={isHeaderWhite ? 'white' : ''}
                    type={'link'}
                    link={Links.Shop}
                />
                <button
                    className={`${styles.toggle} ${isHeaderWhite ? styles.white : ''}`}
                    onClick={openMenu}
                >
                    <div />
                </button>
            </div>

            {isOpenMobileMenu && <HeaderMobile />}
        </header>
    )
};
