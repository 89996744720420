import React from 'react';
import { Home, FixedCustomPipe, AboutPipe, Catalog } from '../pages'
import { Route, Routes } from 'react-router-dom'
import Cart from '../pages/cart/Cart';




function App() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="about-pipe" element={<AboutPipe />} />
                <Route path="catalog" element={<Catalog />} />
                <Route path="/create-pipe-title" element={<FixedCustomPipe />} />
                <Route path="/cart" element={<Cart />} />
            </Routes>
        </>
    )
}

export default App;