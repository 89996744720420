import React, {FC} from "react";
import styles from './Close.module.scss';
import {closeIco} from "../../../assets/icons";
import {CloseProps} from "../../../models/closeProps";

export const Close: FC<CloseProps> = ({
                                          onClick,
                                          size = 24,
                                          className = ''
                                      }) => {
    return (
        <button
            className={`${styles.close} ${className ? className : ''}`}
            onClick={onClick}
        >
            <img
                style={{ width: `${size}px`, height: `${size}px`}}
                src={closeIco}
                alt="close"
            />
        </button>

    )
};
