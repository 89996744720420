import React, {useContext, useEffect, useRef} from "react";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {Title} from "../common/title";
import styles from './FifthScreen.module.scss';
import {Button} from "../common/button";
import {AppContext} from "../AppContainer";
import { Links } from "../../models/static-data/links";
import {ScrollTriggerIds} from "../../models/scrollTriggerIds";
import {mediaData} from "../../models/static-data/media-data";
import {Link} from "../common/link";
import {imageFirst} from "../../assets/images";


gsap.registerPlugin(ScrollTrigger);

export const FifthScreen = () => {
    const screen = useRef(null);
    const {setIsHeaderWhite} = useContext(AppContext);

    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.FifthScreenTrigger,
                trigger: screen.current,
                start: "top top+=10",
                onToggle: ({isActive}) => setIsHeaderWhite(isActive),
            }
        });

        return () => {
            ScrollTrigger.getById(ScrollTriggerIds.FifthScreenTrigger).kill();
        }
    }, []);

    return (
        <div
            style={{height: '200vh', position: "relative"}}
            ref={screen}
        >
            <div className={styles.back}>
                <span  className={styles.img}/>

                <div className={`container ${styles.wrapper}`}>
                    <div className={styles.content}>
                        <Title className={styles.title}>This pipe is made for the Genius in YOU</Title>
                        <Button
                            text={"Buy genius pipe"}
                            mode={"white"}
                            type={'link'}
                            link={Links.Shop}
                        />
                    </div>
                </div>
            </div>
            {/*<div className={`${styles.container}`}>*/}
            {/*    <div className={`container ${styles.content}`}>*/}
            {/*        */}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

    )
};
