import {useContext} from 'react';
import styles from './CustomPipeColorBlock.module.scss';
import {AppContext} from "../../../AppContainer";
import {colors} from '../../../../models/static-data/colors';
import {Circle} from "../../../common/circle";

export const CustomPipeColorBlock = () => {
    const {activeTypePipe, activeColorPipe, setActiveColorPipe} = useContext(AppContext);

    return (
        <div className={styles.color}>
            {colors[activeTypePipe].map(color => {
                return (
                    <Circle
                        key={color.id}
                        isActive={activeColorPipe === color.id}
                        onClick={setActiveColorPipe}
                        background={color.color}
                        name={color.name}
                        id={color.id}
                    />
                )
            })}
        </div>
    )
};
