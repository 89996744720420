import React, { useEffect, useState } from 'react'
import { mobilePopupShow, mobilePopupHide } from '../../assets/images'
import styles from './MobilePopup.module.scss'

interface PopupButtonProps {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setActivePopupItem: React.Dispatch<React.SetStateAction<number>>,
}

function PopupButton({ setVisible, visible, setActivePopupItem }: PopupButtonProps) {

    const [marksElements, setMarksElements] = useState<any>([])

    useEffect(() => {
        let marksElements: Array<HTMLElement> = Array.from(document.querySelectorAll('[data-mark]'))
        const coorditanesMarks = marksElements.map(elem => elem.getBoundingClientRect())
        setMarksElements(coorditanesMarks)
    }, [])


    useEffect(() => {

        window.onscroll = function () {
            if (marksElements.length > 0) {
                marksElements.forEach((el: any, ind: any) => {

                    if (el.top - 100 <= window.pageYOffset) {
                        setActivePopupItem(ind)
                    }
                })
            }
        }
    }, [marksElements])

    return (
        <button className={styles.visible__button} onClick={() => setVisible(!visible)}>
            <img src={visible ? mobilePopupHide : mobilePopupShow} alt="img" />
        </button>
    )
}

export default PopupButton