import React, { useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { Canvas } from "../common/canvas";
import styles from './SixthScreen.module.scss';

gsap.registerPlugin(ScrollTrigger);

export const SixthScreen = () => {
    const sixthScreen = useRef(null);
    const COUNT_FRAMES = 212;
    const dir = 'sixth-screen';

    return (
        <div style={{ height: '1000vh', position: "relative" }} ref={sixthScreen}>
            <Canvas
                screen={sixthScreen}
                countFrames={COUNT_FRAMES}
                dir={dir}
                disappearing
                className={styles.canvas}
            />
        </div>
    )
};
