import React, { useEffect, useState } from 'react'
import MobilePopup from './MobilePopup'
import ButtonPopup from './PopupButton'

interface MobilePopupContainerProps {
    visible: boolean,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setActivePopupItem: React.Dispatch<React.SetStateAction<number>>,
    activePopupItem: number
}


function MobilePopupContainer({ visible, setVisible, activePopupItem, setActivePopupItem }: MobilePopupContainerProps) {

    return (
        <>
            <MobilePopup visible={visible} setVisible={setVisible} activePopupItem={activePopupItem} setActivePopupItem={setActivePopupItem} />
            <ButtonPopup setVisible={setVisible} visible={visible} setActivePopupItem={setActivePopupItem} />
        </>
    )
}

export default MobilePopupContainer