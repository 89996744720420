import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styles from './Smoke.module.scss';
import { Canvas } from "../../common/canvas";
import { Title } from "../../common/title";
import { Button } from "../../common/button";
import { Links } from "../../../models/static-data/links";
import { ScrollTriggerIds } from "../../../models/scrollTriggerIds";

gsap.registerPlugin(ScrollTrigger);

export const Smoke = () => {
    const screen = useRef(null);
    const block = useRef(null);

    useEffect(() => {
        const blockTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.AdvantageBlockTrigger,
                trigger: block.current,
                start: "top top",
                endTrigger: screen.current,
                end: "+=3000",
                scrub: true,
            }
        });

        blockTimeline.from(block.current, { y: 250, opacity: 0, duration: 3 });
        blockTimeline.to(block.current, { y: 0, opacity: 1, duration: 5 });

        return () => {
            ScrollTrigger.getById(ScrollTriggerIds.AdvantageBlockTrigger).kill();
        }
    }, [])

    return (
        <div className={styles.smoke} style={{ height: '500vh', position: "relative", marginBottom: 60 }} ref={screen}>
            <Canvas screen={screen} countFrames={203} dir={'smoke'} className={styles.canvas} appearing start={'-=1000'} />

            <div style={{ position: "sticky", top: '0', height: '100vh' }}>

                <div className={`container ${styles.wrapper}`} ref={block}>
                    <div className={styles.content}>
                        <Title>Healthiest way to smoke</Title>

                        <p className={styles.text}>
                            Traps tar. Protects lungs
                        </p>
                        <Button
                            className={styles.button}
                            link={Links.Shop}
                            type={'link'}
                            text={'Buy genius pipe'}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};
