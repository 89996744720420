import { ColorPipe } from "../settingsPipe";
import {
    miniPipe1, miniPipe2, miniPipe3, miniPipe4, miniPipe5, miniSlider1,
    miniSlider2,
    pipes1, pipes2, pipes3, pipes4, pipes5, pipes6, sliders1
} from "../../assets/images";

const COLOR_URL = "https://geniuspipe.com/collections/genius-colors?_=pf&pf_opt_color=";
const BUY_URL = 'https://geniuspipe.com/cart/add?id='

export const colors = {
    standard: [
        {
            id: ColorPipe.Silver,
            color: 'linear-gradient(180deg, #817C7C 0%, rgba(192, 189, 189, 0.42) 100%)',
            name: 'Silver',
            pipe: pipes1,
            slider: sliders1,
            link: `${BUY_URL}25942735945`,
        }, {
            id: ColorPipe.Black,
            color: 'linear-gradient(180deg, #262221 0%, rgba(38, 34, 33, 0.42) 100%)',
            name: 'Black',
            pipe: pipes2,
            slider: sliders1,
            link: `${BUY_URL}43605917449`
        }, {
            id: ColorPipe.Gold,
            color: '#D7B759',
            name: 'Gold',
            pipe: pipes3,
            slider: sliders1,
            link: `${BUY_URL}43605377225`
        }, {
            id: ColorPipe.Purple,
            color: '#BB67E3',
            name: 'Purple',
            pipe: pipes4,
            slider: sliders1,
            link: `${COLOR_URL}Purple`
        }, {
            id: ColorPipe.Blue,
            color: '#15CDED',
            name: 'Blue',
            pipe: pipes5,
            slider: sliders1,
            link: `${COLOR_URL}Blue`
        }, {
            id: ColorPipe.Red,
            color: '#B40303',
            name: 'Red',
            pipe: pipes6,
            slider: sliders1,
            link: `${COLOR_URL}Red`
        }
    ],
    mini: [{
        id: ColorPipe.Gold,
        name: 'Gold',
        color: '#D7B759',
        pipe: miniPipe3,
        slider: miniSlider1,
        link: `${BUY_URL}39304964571208`,
    }, {
        id: ColorPipe.Blue,
        name: 'Blue',
        color: '#15CDED',
        pipe: miniPipe4,
        slider: miniSlider1,
        link: `${BUY_URL}39304964505672`,
    }, {
        id: ColorPipe.Red,
        color: '#B40303',
        name: 'Red',
        pipe: miniPipe5,
        slider: miniSlider1,
        link: `${BUY_URL}39304964538440`,
    }]
};
