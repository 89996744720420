import { Button } from '../common/button';
import { Title } from '../common/title';
import styles from './Subscribe.module.scss';

export const Subscribe = () => {
    return (
        <div className={styles.subscribe}>
            <Title className={styles.title}>Subscribe For Genius News & Special Offers</Title>

            <form className={styles.form}>
                <input
                    type="email"
                    className={styles.input}
                    placeholder="Enter your e-mail"
                />
                <Button text={'Subscribe'} mode={'black'}/>
            </form>
        </div>
    )
};
