import React from 'react'
import { offersItem } from '../../assets/images'


interface OfferItemProps {
    styles: any
}

function OfferItem({ styles }: OfferItemProps) {
    return (
        <>
            <div className={styles.offers__item}>
                <img src={offersItem} alt="product image" className={styles.item__img} />
                <div className={styles.offers__item_text}>
                    <p className={styles.item__title}>Genius Mini - Silver</p>
                    <span className={styles.item__price}>78$</span>
                </div>
            </div>
        </>
    )
}

export default OfferItem