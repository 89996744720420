import React, { FC, useContext, useState } from 'react';
import { SettingsPipe } from "../../models/settingsPipe";
import styles from "./CustomPipe.module.scss";
import img from "../../assets/1.png";
import { colors } from "../../models/static-data/colors";
import { designPipes } from "../../models/static-data/design-pipes";
import { engraving, sliders1, miniPipe3, miniSlider1 } from '../../assets/images';
import { AppContext } from "../AppContainer";
import { sliders } from "../../models/static-data/sliders";
import { Link as LinkButton } from "../common/link";
import { pipes1 } from '../../assets/images'
import { fixedPipeAC } from '../../redux/actions';
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store';



export const CustomPipeExample: FC = () => {
    const {
        activeTypePipe,
        activeColorPipe,
        activeDesignPipe,
        fileImg,
        isApplyImg,
        activeSlider,
        countEngravingLine,
        isMobile,
        imageToggleFixedPipe
    } = useContext(AppContext);


    const dispatch = useDispatch()
    const customPipeValue1 = useSelector((state: RootState) => state.FixedCustomPipe.custopPipeValue1)
    const customPipeValue2 = useSelector((state: RootState) => state.FixedCustomPipe.custopPipeValue2)
    const activeTab = useSelector((state: RootState) => state.CustomPipeExample.activeTab)
    const countLines = useSelector((state: RootState) => state.FixedCustomPipe.countLines)



    // const drawEngravingLine = () => {
    //     switch (countEngravingLine) {
    //         case 1:
    //             return <p>Have a nice day!</p>;
    //         case 2:
    //             return <>
    //                 <p style={{ marginBottom: '10px' }}>Have a nice day!</p>
    //                 <p>{customPipeValue1}</p>
    //             </>
    //         default:
    //             return null;
    //     }
    // };

    const onClickImage = () => {
        dispatch(fixedPipeAC(true))
        document.body.style.overflowY = 'hidden'
        localStorage.setItem('scrolled', JSON.stringify(window.pageYOffset))
        // imageToggleFixedPipe.current!.focus({ preventScroll: true })
        //@ts-ignore
    }


    switch (activeTab) {
        case SettingsPipe.Design:
            return isApplyImg ?
                (
                    <div className={styles.img} style={{ position: 'relative', overflow: "hidden" }}>
                        {/*@ts-ignore*/}
                        <img src={img} className={styles.mask} alt="" style={{ backgroundImage: `url(${fileImg[0]?.preview})` }} />
                        <img src={sliders1} alt="" />
                    </div>
                ) : (
                    <div className={styles.design}>
                        <img src={designPipes.find((pipe: any) => pipe.id === activeDesignPipe)?.pipe}
                            alt=""
                        />
                    </div>
                )
        case SettingsPipe.Engraving:
            return (
                <div>

                    <div className={styles.engraving}>
                        <div className={styles.text}>
                            <input type="text" onChange={() => { }} value={customPipeValue1} placeholder='enter your custom title...' />
                            {countLines > 1 && <input type="text" onChange={() => { }} value={customPipeValue2} placeholder='enter your custom title...' />}
                        </div>
                        <Link to="create-pipe-title">
                            <img src={engraving} alt="" onClick={onClickImage} />
                        </Link>

                    </div>
                    {
                        isMobile &&
                        <LinkButton
                            margin="65px"
                            className={styles.link}
                            text={'Choose your text for engraving'}
                            mode={'white'}
                            link={'https://geniuspipe.com/collections/genius-classic/products/genius-gadget'}
                        />
                    }
                </div>

            );
        case SettingsPipe.Slider:
            return (
                <div className={styles.colors} >
                    {/*@ts-ignore*/}
                    <img src={colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.pipe || pipes1}
                        alt="" />
                    {activeSlider >= 0 &&
                        <img
                            src={sliders.find((slide: any) => slide.id === activeSlider)?.slider || sliders1}
                            alt=""
                        />
                    }
                </div>
            );
        default:
            return (
                <div className={styles.colors}>
                    {/*@ts-ignore*/}
                    <img src={colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.pipe || miniPipe3}
                        alt="" />
                    {/*@ts-ignore*/}
                    <img
                        src={colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.slider || miniSlider1}
                        alt="" />
                </div>
            )
    }
};
