import React, { useContext, useEffect, useRef } from "react";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { AppContext } from "../AppContainer";
import { Canvas } from "../common/canvas";
import { Title } from "../common/title";
import styles from './ForthScreen.module.scss';
import { ScrollTriggerIds } from "../../models/scrollTriggerIds";

gsap.registerPlugin(ScrollTrigger);

export const ForthScreen = () => {
    const { setIsHeaderWhite } = useContext(AppContext);
    const forthScreen = useRef(null);
    const block = useRef(null);
    const firstParagraph = useRef(null);
    const secondParagraph = useRef(null);
    const COUNT_FRAMES = 46;
    const dir = 'forth-screen';

    useEffect(() => {
        const titleTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.ForthScreenTitleTrigger,
                trigger: forthScreen.current,
                start: "top top",
                end: "bottom bottom",
                scrub: 1,
                onEnter: () => {
                    setIsHeaderWhite(false);
                },
                onEnterBack: () => {
                    setIsHeaderWhite(false);
                },
                onToggle: () => {
                    setIsHeaderWhite(false);
                }
            }
        });

        titleTimeline.from(block.current, { y: 50, opacity: 0 });
        titleTimeline.to(block.current, { y: -100, opacity: 0, delay: 2 });

        const firstTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.ForthScreenFirstParagraphTrigger,
                trigger: block.current,
                start: "+=400",
                end: "-=300",
                scrub: 1
            }
        });

        const secondTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.ForthScreenSecondParagraphTrigger,
                trigger: secondParagraph.current,
                start: "top top ",
                scrub: 1
            }
        });

        firstTimeline.from(firstParagraph.current, { y: 50, opacity: 0 });
        secondTimeline.from(secondParagraph.current, { y: 50, opacity: 0, delay: 10 });

        return () => {
            ScrollTrigger.getById(ScrollTriggerIds.ForthScreenTitleTrigger).kill();
            ScrollTrigger.getById(ScrollTriggerIds.ForthScreenFirstParagraphTrigger).kill();
            ScrollTrigger.getById(ScrollTriggerIds.ForthScreenSecondParagraphTrigger).kill();
        }
    }, [])

    return (
        <div style={{ height: '300vh' }} ref={forthScreen}>
            <Canvas
                screen={forthScreen}
                countFrames={COUNT_FRAMES}
                dir={dir}
                className={styles.canvas}
            />
            <div className={styles.container} ref={block}>
                <Title className={styles.title}>
                    Greate Your Own Pipe.
                </Title>
                <p className={styles.text} ref={firstParagraph}>Enjoy all the flavors… As never before</p>
                <p className={styles.text} ref={secondParagraph}>Anodized aluminum for no metallic taste Pure Genius.</p>
            </div>
        </div>
    )
};
