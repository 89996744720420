import React, { useState, useRef, useEffect, useContext } from 'react'
import styles from "./FixedCustomPipe.module.scss"
import { engravingTransparent } from '../../assets/images';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from "../../redux/store"
import { fixedPipeAC, changeCustomValue1AC, changeCustomValue2AC, changeActiveTabAC, changeCountLinesAC } from '../../redux/actions'
import { fonts, lines } from '../../models/static-data/engraving';
import { AppContext } from '../../components/AppContainer';
import { Link } from 'react-router-dom'

export function FixedCustomPipe() {
    const [fontFamily, setFontFamily] = useState('Georgia');
    const { imageToggleFixedPipe } = useContext(AppContext)

    const customPipeValue1 = useSelector((state: RootState) => state.FixedCustomPipe.custopPipeValue1)
    const customPipeValue2 = useSelector((state: RootState) => state.FixedCustomPipe.custopPipeValue2)
    const visibleBlock = useSelector((state: RootState) => state.FixedCustomPipe.visibleFixedPipe)

    const countLines = useSelector((state: RootState) => state.FixedCustomPipe.countLines)
    const dispatch = useDispatch()


    const refInput2 = useRef<HTMLInputElement>(null)
    const imageWrapper = useRef<HTMLDivElement>(null)


    const onClickOkayBtn = (e: any) => {


        dispatch(fixedPipeAC(false))
        setTimeout(() => {
            // @ts-ignore
            window.scrollTo({
                // @ts-ignore
                top: +localStorage.getItem('scrolled'),
            })
            dispatch(changeActiveTabAC(4))


            // @ts-ignore
        }, 400)
        document.body.style.overflowY = 'auto'
    }

    return (
        <>
            {visibleBlock && <div className={styles.fixedPipe_wrapper}>

                <div className={styles.settings}>

                    {
                        lines.map(line => (
                            <button
                                key={line.count}
                                className={`${styles.item} ${countLines === line.count ? styles.active : ''}`}
                                onClick={() => dispatch(changeCountLinesAC(line.count))}
                            >
                                {line.text}
                            </button>
                        ))
                    }
                </div>

                <div className={styles.settings}>
                    {
                        fonts.map(font => (
                            <button
                                key={font}
                                className={`${styles.item} ${fontFamily === font ? styles.active : ''}`}
                                onClick={() => setFontFamily(font)}
                            >
                                {font}
                            </button>
                        ))
                    }
                </div>

                <div ref={imageWrapper} className={styles.image__wrapper}>
                    <img className={styles.pipe__img} src={engravingTransparent} alt="pipe" />
                    <div className={styles.fixedPipe_text}>
                        {<input style={{ fontFamily: fontFamily }} type="text" onChange={() => dispatch(changeCustomValue1AC(imageToggleFixedPipe.current?.value))} value={customPipeValue1} placeholder="type customize value..." ref={imageToggleFixedPipe} />}
                        {countLines > 1 && <input style={{ fontFamily: fontFamily }} type="text" onChange={() => dispatch(changeCustomValue2AC(refInput2.current?.value))} value={customPipeValue2} placeholder="type customize value..." ref={refInput2} />}
                    </div>
                </div>

                <div >
                    <Link to="/">
                        <button onClick={onClickOkayBtn} className={styles.confirm}>Okay</button>
                    </Link>
                </div>
            </div>}
        </>

    )
}