import {reviews1, reviews2, reviews3, reviews4} from "../../assets/icons";

export const reviewsData = [
    {
        id: 1,
        avatar: reviews1,
        name: 'Michelle',
        date: '06/14/2021',
        text: 'Great convenient on the go pipe! Very easy to clean, cools the smoke down so it’s a less harsh hit. It’s my new go to pipe for smoking anywhere!',
        value: 5
    },{
        id: 2,
        avatar: reviews2,
        name: 'Nancy',
        date: '05/14/2021',
        text: 'This is a nice pipe It’s small fits perfect in my hand and it’s Red',
        value: 5
    },{
        id: 3,
        avatar: reviews3,
        name: 'William',
        date: '06/01/2021',
        text: 'Excellent Pipe! Loved it so much, I bought 5 of them for Friends and Family!!!! Stay away from the knock-offs! The magnets come loose, etc. BUY ONLY from Genius Pipe or someone they recommend! Again, the Original from Genius Pipe is a Fantastic Product!!! HIGH-ly Recommended!!! ',
        value: 5
    },{
        id: 4,
        avatar: reviews4,
        name: 'Sean',
        date: '05/28/2021',
        text: 'I used to never clean my unit. With Genius is so easy! I just run it under hot water, no mess, and takes seconds. Its has a nice smoke.\n',
        value: 5
    },{
        id: 5,
        avatar: reviews1,
        name: 'Ryan',
        date: '06/14/2021',
        text: 'Best pipe in the market I’ve always wanted one since released and I finally got one! It’s like Kirby vacuums once you buy it you’ll never have to worry again\n',
        value: 5
    },{
        id: 6,
        avatar: reviews2,
        name: 'Tim',
        date: '05/14/2021',
        text: 'This is all I use now. They are outstanding for long timers. They are slightly more susceptible to being dropped because of their beautiful shape and sliding parts, which can put in tiny dents that effect the seal. So careful.',
        value: 5
    }
]