
const initialState = {
    activeTab: 1
}

function CustomPipeExampleReducer(state = initialState, action: any) {


    switch (action.type) {
        case "setActiveTab":
            return {
                ...state,
                activeTab: action.payload
            }
        default:
            return state
    }
}

export default CustomPipeExampleReducer