import React from 'react'
import { setFilterBy } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import classes from './Filter.module.scss'
import { RootState } from '../../../redux/store'


interface FilterProps {
    styles: any
}

const menuItems = [
    { title: 'All', filerBy: '1' },
    { title: 'Classic', filerBy: '-filter-classic' },
    { title: 'Colors', filerBy: 'genius-colors' },
    { title: 'Limited', filerBy: 'genius-limited' },
    { title: 'Light', filerBy: 'genius-light' },
    { title: 'Accessories', filerBy: 'accessories' },
    { title: 'Genius Kits', filerBy: '-filter-kits' },
    { title: 'Genius Wear', filerBy: 'genius-wear' },
    { title: 'Karma Store', filerBy: 'genius-karma' }

]

// kits -> -filter-kits
// classic -> -filter-classic

// smoke drop
// limited
// karma
// vintage
// cannareps
// accessory
// pouch
// genius art
// genius tribe
// limited
// trending
// top secret ws
// colors
// karma action
// custom art
// sample
// sliding cover
// trending
// g-stone
// discontinued
// cap
// genius taster
// nail
// subscription
// Gadget
// bluedream ws
// classic
// genius wear
// wholesale
// deals
// stationery
// pen
// repair kit
// silicone box
// screens
// redeem
// light
// container
// storage
// ebay
// sample
// insert
// sliders
// pipe bases
// insert
// black friday
// promotional


// ------------------------------

// is: 
// all-genius
// genius-classic
// genius-colors
// genius-limited
// genius-light
// accessories
// kits
// genius-wear
// genius-karma



// ------------------------------

// most-popular
//wholesale
//genius-karma-actions
//best-deals
// only-pipes = 0 products
//prepaid-karma
// all-pipes ? 
// retail
// gift-products
// subscription
// 
let count = 0

function Filter({ styles }: FilterProps) {

    const dispatch = useDispatch()

    //@ts-ignore
    const filterValues = useSelector((state: RootState) => state.shop.filterValues)
    console.log('render ', count++)
    return (
        <>
            <ul className={classes.catalog__sort}>
                <li className={`${classes.filter__title}`}>category</li>
                {
                    menuItems.map(item => {
                        return (
                            <li onClick={() => dispatch(setFilterBy(item.filerBy))} key={item.title + item.filerBy}>
                                <label className={classes.container}>
                                    <input type="checkbox" checked={filterValues.includes(item.filerBy)} />
                                    <span className={classes.checkmark}></span>
                                </label><span>{item.title}</span>
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
}

export default Filter