import React from "react";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {Title} from "../common/title";
import styles from './Lifestyle.module.scss';
import {Button} from "../common/button";
import { Links } from "../../models/static-data/links";

gsap.registerPlugin(ScrollTrigger);

export const Lifestyle = () => {

    return (
        <div className={`${styles.container}`}>
            <div className={`container ${styles.content}`}>
                <Title className={styles.title}>This pipe is made for the Genius in YOU</Title>
                <Button text={"Buy genius pipe"} type={'link'} link={Links.Shop} />
            </div>
        </div>
    )
};
