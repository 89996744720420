export enum ScrollTriggerIds {
    AdvantageBlockTrigger = 'AdvantageBlockTrigger',

    CanvasFirstScreenRenderTrigger = 'CanvasFirstScreenBlockTrigger',
    CanvasFirstScreenMoveTrigger = 'CanvasFirstScreenMoveTrigger',
    CanvasFirstScreenDecreaseTrigger = 'CanvasFirstScreenDecreaseTrigger',
    CanvasFirstScreenDisappearTrigger = 'CanvasFirstScreenDisappearTrigger',

    CanvasCommonRenderTrigger = 'CanvasCommonRenderTrigger',
    CanvasCommonAppearTrigger = 'CanvasCommonAppearTrigger',
    CanvasCommonDisappearTrigger = 'CanvasCommonAppearTrigger',

    DescriptionBlockTrigger = 'DescriptionBlockTrigger',
    DescriptionParagraphTrigger = 'DescriptionParagraphTrigger',

    FifthScreenTrigger = 'FifthScreenTrigger',

    IntroScreenTrigger = 'IntroScreenTrigger',

    ForthScreenTitleTrigger = 'ForthScreenTitleTrigger',
    ForthScreenFirstParagraphTrigger = 'ForthScreenFirstParagraphTrigger',
    ForthScreenSecondParagraphTrigger = 'ForthScreenSecondParagraphTrigger',

    GeniusMiniTitleTrigger = 'GeniusMiniTitleTrigger',
    GeniusMiniParagraphTrigger = 'GeniusMiniParagraphTrigger',
    GeniusMiniSizeTrigger = 'GeniusMiniSizeTrigger',

    MediaFirstBlockTrigger = 'MediaFirstBlockTrigger',
    MediaSecondBlockTrigger = 'MediaSecondBlockTrigger',
    MediaThirdBlockTrigger = 'MediaThirdBlockTrigger',

    ReviewFirstBlockTrigger = 'ReviewFirstBlockTrigger',
    ReviewSecondBlockTrigger = 'ReviewFirstBlockTrigger',
    ReviewThirdBlockTrigger = 'ReviewFirstBlockTrigger',

    VideoBlockTrigger = 'VideoBlockTrigger',
    VideoHeaderTrigger = 'VideoHeaderTrigger',
    VideoFirstTextTrigger = 'VideoFirstTextTrigger',
    VideoSecondTextTrigger = 'VideoBlockTrigger',

    CustomPipeTrigger = 'CustomPipeTrigger',
}
