import {MediaBlockI} from "../mediaBlockI";
import {media1, media2, media3} from "../../assets/images";

export const mediaData: MediaBlockI[] = [
    {
        id: 1,
        image: media1,
        text: 'I’ve been slowly getting hip to concentrates and LOVE the Genius Pipe with G-Stone, a ceramic sponge that absorbs concentrates, so you can dab on the go and without a rig'
    },{
        id: 2,
        image: media2,
        text: 'The unique design-slider makes every hit taste amazing and won’t make you cough! It’s an absolute must if you want to smoke discreetly.'
    },{
        id: 3,
        image: media3,
        text: 'They don`t call it genius for nothing.'
    },
]