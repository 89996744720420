import React from 'react'

interface CartItemProps {
    styles: any,
    title: string,
    image: string,
    price: number,
    quantity: number,
    totalPrice: number
}
function CartItem({ styles, image, price, quantity, totalPrice }: CartItemProps) {
    return (
        <>
            <div className={styles.cart__item}>
                <div className={`${styles.container}`}>
                    <img src={image} alt="product" className={styles.item__image} />
                    <span className={styles.item__title}>Genius Mini - Silver</span>
                    <span className={styles.item__price}>{price / 10}$</span>
                    <div className={styles.count_wrapper}>
                        <button className={styles.count__minus}>-</button>
                        <span className={styles.count}>{quantity}</span>
                        <button className={styles.count__plus}>+</button>
                    </div>
                    <span className={styles.item__total_price}>{totalPrice / 10}$</span>
                    <button className={`${styles.cart__cancel} ${styles.item__cancel}`}></button>
                </div>
            </div>
        </>
    )
}

export default CartItem