import React, {FC} from "react";
import styles from './ReviewBlock.module.scss';
import {star} from "../../../assets/icons";
import {Review} from "../../../models/reviews";

export const ReviewBlock: FC<Review> = ({name, avatar, text, date}) => {
    return (
        <div className={styles.block}>
            <div className={styles.top}>
                <div className={styles.avatar}>
                    <img src={avatar} alt="" />
               </div>
                <div>
                    <p className={styles.name}>{name}</p>
                    <p className={styles.date}>{date}</p>
                </div>
            </div>

            <div className={styles.stars}>
                <img src={star} alt=""/>
                <img src={star} alt=""/>
                <img src={star} alt=""/>
                <img src={star} alt=""/>
                <img src={star} alt=""/>
            </div>

            <p>{text}</p>
        </div>
    )
};
