import React from 'react'
import { Link } from 'react-router-dom'
import { catalogProduct, ratingWhiteStar, ratingBlackStar } from '../../../assets/images'
import { setAboutData } from '../../../redux/actions'

interface CatalogProductProps {
    styles: any,
    imgSrc: string,
    price: string,
    title: string,
    dispatch: (action: any) => {}
}

function CatalogProduct({ styles, imgSrc, price, title, dispatch }: CatalogProductProps) {
    return (
        <>
            <div className={styles.product__wrapper}>
                <div className={styles.product}>
                    <img src={imgSrc} alt="product image" className={styles.product__image} />
                    <p className={styles.product__title}>{title}</p>
                    <p className={styles.product__price}>${price}</p>
                    <div className={styles.product__rating}>
                        <img src={ratingWhiteStar} alt="" />
                        <img src={ratingWhiteStar} alt="" />
                        <img src={ratingWhiteStar} alt="" />
                        <img src={ratingWhiteStar} alt="" />
                        <img src={ratingBlackStar} alt="" />
                    </div>
                    <Link to="/about-pipe">
                        <button className={styles.product__button} onClick={() => dispatch(setAboutData({ image: imgSrc, price, title }))}>VIEW PRODUCT</button>
                    </Link>
                </div>
            </div>
        </>
    )
}

export default CatalogProduct