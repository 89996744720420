import React, {FC} from "react";
import styles from './ReviewSlider.module.scss';
import {useKeenSlider} from "keen-slider/react";
import "keen-slider/keen-slider.min.css"
import {ReviewBlock} from "../review-block";
import {reviewsData} from "../../../models/static-data/reviews-data";

export const ReviewSlider: FC = () => {
    const [sliderRef] = useKeenSlider<HTMLDivElement>({
        slidesPerView: 1.5,
        mode: "free-snap",
        spacing: 60,
        initial: 0,
    });

    return (
        <div className={styles.slider}>
            <div ref={sliderRef} className="keen-slider">
                {
                    reviewsData.map((review) => {
                            return (
                                <div className={`keen-slider__slide ${styles.slide}`} key={review.id}>
                                    <ReviewBlock
                                        id={review.id}
                                        avatar={review.avatar}
                                        name={review.name}
                                        text={review.text}
                                        date={review.date}
                                    />
                                </div>
                            )
                        }
                    )
                }
            </div>
        </div>
    )
};
