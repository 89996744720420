export enum SettingsPipe {
    Color = 1,
    Design = 2,
    Slider = 3,
    Engraving = 4
}

export enum ColorPipe {
    Silver = 1,
    Black,
    Gold,
    Purple,
    Blue,
    Red
}

export enum kindPipe {
    Standard = 'standard',
    Mini = 'mini'
}

export enum limitedPipe {
    Cockpaw,
    Frivolous,
    Holiday,
    Candy,
    Hanukkah,
    Herb,
    Kwanzaa,
    Mexico,
    Cats,
    Roger,
    Wolf,
    Roller,
    RollerCherry,
    Hypnotic,
    Independence,
    Liberty,
    Mad,
    MonaLisa,
    Night,
    Passion,
    Psychedelic,
    Rainbow,
    Queen,
    Samsara,
    Portrait,
    StarryNight,
    Stunt
}
