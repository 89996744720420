import React, {FC} from "react";
import { loaderIco } from "../../../assets/icons";
import styles from './Loader.module.scss';

export const Loader: FC = () => {
    return (
        <div className={styles.wrapper}>
            <img src={loaderIco} alt="Loading..." className={styles.loader}/>
        </div>
    )
};
