// @ts-nocheck


const initialState = {
    totalCount: 0,
    totalPrice: 0,
    products: [],
    filteredProducts: [],
    filterValues: [],
    checkout: {}
}

interface Action {
    type: string,
    payload: string | any
}

function shopReducer(state = initialState, action: Action) {
    switch (action.type) {
        case "setProducts":

            let pipes = []
            let otherProducts = []

            for (let product of action.payload) {
                if (product.product_type.toLowerCase() === 'pipe' && product.tags.toLowerCase() !== 'smoke drop') {
                    pipes.push(product)
                } else {
                    otherProducts.push(product)
                }
            }

            return {
                ...state,
                products: [...pipes, ...otherProducts],
                filteredProducts: [...pipes, ...otherProducts]
            }
        case "setFilteredProducts":
            return {
                ...state,
                filteredProducts: action.payload
            }
        case "setFilterBy": {
            // let filteredProducts: any = []
            // let newFilterValues: any = []
            // if (state.filterValues.includes(action.payload)) {
            //     // cancel
            //     for (let product of state.products) {
            //         if (!product.tags.split(',').map(str => str.trim().toLowerCase()).includes(action.payload)) {
            //             filteredProducts.push(product)
            //         }

            //         let tempFilterValues = [...state.filterValues]
            //         let indexPayloadValue = tempFilterValues.indexOf(action.payload)
            //         if (indexPayloadValue !== 1) {
            //             tempFilterValues.splice(indexPayloadValue, 1)
            //         }
            //         newFilterValues = tempFilterValues
            //     }
            // } else {
            //     // add
            //     for (let product of state.products) {
            //         if (!product.tags.split(',').map(str => str.trim().toLowerCase()).includes(action.payload)) {
            //             filteredProducts.push(product)

            //         }
            //     }
            //     newFilterValues = [...state.filterValues, action.payload]
            // }

            let newFilterValues = []

            if (state.filterValues.includes(action.payload)) {
                let tempFilterValues = [...state.filterValues]
                let indexPayloadValue = tempFilterValues.indexOf(action.payload)
                if (indexPayloadValue !== -1) {
                    tempFilterValues.splice(indexPayloadValue, 1)
                }
                newFilterValues = tempFilterValues
            } else {
                newFilterValues = [...state.filterValues, action.payload]
            }


            return {
                ...state,
                filterValues: newFilterValues
            }

        }


        case "setCheckout":
            return {
                ...state,
                checkout: action.payload
            }



        default:
            return state
    }
}

export default shopReducer