import {
    miniSliders1,
    miniSliders3,
    miniSliders4,
    miniSliders5,
    miniSliders6,
    miniSliders7, sliders1, sliders2,
    sliders3, sliders7, sliders8,
    sliders9
} from "../../assets/images";

export const sliders = [
    {
        id: 1,
        icon: miniSliders1,
        slider: sliders3,
        link: 'https://geniuspipe.com/collections/accessories/products/evolution-slider-gold',
    },{
        id: 3,
        icon: miniSliders3,
        slider: sliders2,
        link: 'https://geniuspipe.com/collections/accessories/products/evolution-slider-black',

    },{
        id: 4,
        icon: miniSliders4,
        slider: sliders1,
        link: 'https://geniuspipe.com/collections/accessories/products/evolution-slider-silver',
    },{
        id: 5,
        icon: miniSliders5,
        slider: sliders9,
        link: 'https://geniuspipe.com/collections/accessories/products/water-free-dab-rig-upgrade'
    },{
        id: 6,
        icon: miniSliders6,
        slider: sliders8,
        link: 'https://geniuspipe.com/collections/accessories/products/water-free-dab-rig-upgrade'
    },{
        id: 7,
        icon: miniSliders7,
        slider: sliders7,
        link: 'https://geniuspipe.com/collections/accessories/products/water-free-dab-rig-upgrade'
    },
];
