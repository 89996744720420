import React, {useEffect, useRef} from "react";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import styles from './Media.module.scss';
import {media} from "../../assets/images";
import {Title} from "../common/title";
import {mediaData} from "../../models/static-data/media-data";
import {Link} from "../common/link";
import {ScrollTriggerIds} from "../../models/scrollTriggerIds";

gsap.registerPlugin(ScrollTrigger);

export const Media = () => {
    const block = useRef(null);
    const first = useRef(null);
    const second = useRef(null);
    const third = useRef(null);
    const force = useRef(null);

    useEffect(() => {
        const firstTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.MediaFirstBlockTrigger,
                trigger: block.current,
                start: "top top",
                end: `top top-=${window.innerHeight}`,
                scrub: 1,
            },
        });

        firstTimeline.to(first.current, {x: -100, opacity: 0})

        const secondTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.MediaSecondBlockTrigger,
                trigger: block.current,
                start: `top top-=${window.innerHeight}`,
                end: `center center-=${window.innerHeight}`,
                scrub: 1,
            }
        });

        secondTimeline.from(second.current, {x: 100, opacity: 0});
        secondTimeline.to(second.current, {x: -100, opacity: 0});

        const thirdTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.MediaThirdBlockTrigger,
                trigger: block.current,
                start: `center center-=${window.innerHeight}`,
                scrub: 1,
            }
        });

        thirdTimeline.from(third.current, {x: 100, opacity: 0});
        thirdTimeline.from(force.current, {y: 20, opacity: 0});
        thirdTimeline.to(force.current, {y: 0, opacity: 1, animationTimingFunction: 'easy-in'});

        return () => {
            ScrollTrigger.getById(ScrollTriggerIds.MediaFirstBlockTrigger).kill();
            ScrollTrigger.getById(ScrollTriggerIds.MediaSecondBlockTrigger).kill();
            ScrollTrigger.getById(ScrollTriggerIds.MediaThirdBlockTrigger).kill();
        }
    }, [])

    return (
        <div
            style={{height: '400vh', position: "relative"}}
            ref={block}
        >
            <div className={styles.back}>
                <img src={media} alt="" className={styles.img}/>

                <div className={`container ${styles.wrapper}`}>
                    <div className={styles.content}>
                        <Title className={styles.title}>Genius in the Media</Title>

                        <div className={styles.blocks}>
                            <div className={styles.block} ref={first}>
                                <img src={mediaData[0].image} alt=""/>
                                <div>{mediaData[0].text}</div>
                            </div>


                            <div className={styles.block} ref={second}>
                                <img src={mediaData[1].image} alt=""/>
                                <div>{mediaData[1].text}</div>
                            </div>

                            <div className={styles.block} ref={third}>
                                <img src={mediaData[2].image} alt=""/>
                                <div>{mediaData[2].text}</div>
                            </div>
                        </div>

                        <div ref={force}>
                            <Link
                                text={"explore more articles"}
                                link={"/"}
                                mode={"white"}
                                className={styles.link}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
