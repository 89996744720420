import {menu, social} from "../../models/static-data/footer";
import {Logo} from "../common/logo";
import styles from './Footer.module.scss';

export const Footer = () => {
    const date = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <div className={styles.copyright}>
                <Logo width={120} height={45}/>
                <p>&copy; {date}, Genius One</p>
            </div>

            {menu.map((item, i) =>
                <div key={i} className={styles.block}>
                    <ul>
                        {
                            item.map(({title, link, id}) =>
                                <li key={id}>
                                    <a
                                        href={link}
                                        className={styles.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {title}
                                    </a>
                                </li>
                            )
                        }
                    </ul>
                </div>
            )}

            <div className={`${styles.block} ${styles.socials}`}>
                {
                    social.map(({id, icon, link, title}) =>
                        <a
                            href={link}
                            key={id}
                            className={styles.social}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={icon} alt={title}/>
                        </a>
                    )
                }
            </div>
        </footer>
    )
};
