import React, {useContext} from 'react';
import {useDropzone} from 'react-dropzone';
import styles from './ImageUpload.module.scss';
import {Button} from "../button";
import {fileIco} from '../../../assets/icons';
import {Close} from "../close";
import {AppContext} from "../../AppContainer";

export function ImageUpload() {
    const {fileImg, setFileImg, setIsApplyImg, closeModal, isIPad} = useContext(AppContext);
    const {
        fileRejections,
        getRootProps,
        getInputProps,
        open
    } = useDropzone({
        maxSize: 1e+7,
        noClick: true,
        maxFiles: 1,
        multiple: false,
        accept: 'image/jpeg, image/png',
        onDrop: acceptedFiles => {
            setFileImg(acceptedFiles.map(file => {
                applyImg();

                return ({
                    ...file, preview: URL.createObjectURL(file)
                })
            }));
        }
    });

    const removeFileItem = () => {
        setFileImg([{}]);
        setIsApplyImg(false);
    };

    const applyImg = () => {
        setIsApplyImg(true);
        closeModal();
    };

    const fileRejectionItems = fileRejections.map(({file, errors}) => (
        //@ts-ignore
        <li key={file.path}> {file.path}
            <ul>
                {errors.map(e => <li key={e.code}>{e.message}</li>)}
            </ul>
        </li>
    ));

    return (
        <div {...getRootProps({className: styles.dropzone})} style={{color: 'black'}}>
            {
                Object.keys(fileImg[0]).length > 0 ? (
                    <>
                        {/*@ts-ignore*/}
                        <div key={fileImg[0].path} className={styles.file}>
                            <img src={fileIco} alt="" className={styles.icon}/>
                            {/*@ts-ignore*/}
                            <p className={styles.text}>{fileImg[0].path}</p>
                            <Close onClick={removeFileItem} size={12} className={styles.close}/>
                        </div>
                    </>
                ) : (
                    <>
                        <input {...getInputProps()} />
                        <Button
                            text={'Upload image'}
                            onClick={open}
                            mode={"white"}
                            className={styles.button}
                        />
                        {
                            !isIPad && (
                                <p className={styles.text}>Or just drag & drop your image here</p>
                            )
                        }
                        <p className={styles.hint}>The maximum file size is 10 MB (PNG or JPEG)</p>
                        {fileRejectionItems}
                    </>
                )
            }
        </div>
    );
}
