import {FC} from "react";
import styles from './Button.module.scss';
import {ButtonProps} from "../../../models/buttonProps";

export const Button: FC<ButtonProps> = ({
                                            text = '',
                                            onClick = () => {
                                            },
                                            className = '',
                                            mode,
                                            disabled = false,
                                            type,
                                            link
                                        }) => {
    const handleClick = () => {
        if (!disabled) onClick();
    };

    return type === 'link' ? (
        <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={`${styles.button} ${className} ${mode ? styles[mode] : ''} ${disabled ? styles.disabled : ''}`}
        >
            {text}
        </a>
    ) : (
        <button
            disabled={disabled}
            type="button"
            onClick={handleClick}
            className={`${styles.button} ${className} ${mode ? styles[mode] : ''} ${disabled ? styles.disabled : ''}`}
        >
            {text}
        </button>
    )
};
