import React, {useContext, useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {Title} from "../common/title";
import styles from './GeniusMini.module.scss';
import {Button} from "../common/button";
import {mini, noMini, pipeSizes2} from "../../assets/images";
import {Links} from "../../models/static-data/links";
import {ScrollTriggerIds} from "../../models/scrollTriggerIds";
import {AppContext} from "../AppContainer";

gsap.registerPlugin(ScrollTrigger);

export const GeniusMini = () => {
    const [hide, setHide] = useState(false);
    const screen = useRef(null);
    const block = useRef(null);
    const firstParagraph = useRef(null);
    const miniPipeBlock = useRef(null);
    const pipeBlock = useRef(null);
    const sizeBlock = useRef(null);
    const {isMobile, isIPad, isNotebook} = useContext(AppContext);

    useEffect(() => {
        const titleTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.GeniusMiniTitleTrigger,
                trigger: screen.current,
                start: "top top",
                end: "bottom bottom+=300",
                scrub: 1,
            }
        });

        const coords = !isNotebook ? {x: '55%', y: '10%'} : {x: '60%', y: '40%'};

        const anim = gsap.fromTo(miniPipeBlock.current, {
            x: '-100%',
            y: '-100%',
            animationTimingFunction: 'easy-in',
            opacity: 0,
            duration: 1,
            delay: 1,
        }, {
            ...coords, opacity: 1, duration: 1,
            onComplete() {
                gsap.fromTo(sizeBlock.current, {opacity: 0}, {opacity:1});
                setHide(true);
            }
        });

        ScrollTrigger.create({
            trigger: screen.current,
            start: "top top+",
            animation: anim,
            toggleActions: "play complete pause play"
        });

        titleTimeline.from(block.current, {y: 50, opacity: 0});

        const firstTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.GeniusMiniParagraphTrigger,
                trigger: screen.current,
                start: "center center",
                scrub: 1,
            }
        });

        firstTimeline.from(firstParagraph.current, {y: 50, opacity: 0});

        const sizeTimeLine = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.GeniusMiniSizeTrigger,
                trigger: screen.current,
                start: "center center",
                scrub: 1,
            }
        });

        sizeTimeLine.to(miniPipeBlock.current, {opacity: 0});
        sizeTimeLine.to(pipeBlock.current, {opacity: 0});

        return () => {
            ScrollTrigger.getById(ScrollTriggerIds.GeniusMiniTitleTrigger).kill();
            ScrollTrigger.getById(ScrollTriggerIds.GeniusMiniParagraphTrigger).kill();
            ScrollTrigger.getById(ScrollTriggerIds.GeniusMiniSizeTrigger).kill();
        }
    }, []);

    return (
        <div
            style={{height: '300vh', position: "relative"}}
            ref={screen}
            className={styles.container}
        >

            {
                !isMobile &&
                <div className={styles.back}>
                    <img src={pipeSizes2} alt="" className={styles.img} ref={sizeBlock}/>

                    {
                        !isIPad &&
                        <>
                            {!hide && <div className={styles.mini} ref={miniPipeBlock}>
                                <img src={mini} alt=""/>
                            </div>}

                            <div>
                                <img src={noMini} alt="" className={styles.pipe} ref={pipeBlock}/>
                            </div>
                        </>
                    }

                </div>
            }

            <div className={`${styles.wrapper} container`}>
                <div ref={block} className={styles.content}>
                    {isMobile &&
                        <img src={pipeSizes2} alt="" className={styles.img} ref={sizeBlock}/>
                    }
                    <Title className={styles.title}>Meet our Genius Mini</Title>
                    <div ref={firstParagraph}>
                        <p className={styles.text}>
                            The new Genius Mini is your perfectly dimensioned
                            companion, guaranteed to make you smile through all of
                            life’s small but MINIngful moments.
                        </p>

                        <Button
                            className={styles.button}
                            text="Buy genius mini"
                            type={'link'}
                            link={Links.BuyMini}
                        />
                    </div>
                </div>

            </div>
        </div>
    )
};
