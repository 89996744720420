import React, { MutableRefObject, useEffect, FC } from "react";
import { gsap } from "gsap";
import styles from './Intro.module.scss';
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollTriggerIds } from "../../../models/scrollTriggerIds";

gsap.registerPlugin(ScrollTrigger);

export const Intro: FC<{ introBlock: MutableRefObject<(HTMLDivElement | null)> }> = ({
    introBlock
}) => {
    useEffect(() => {
        const pTimeline = gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.IntroScreenTrigger,
                trigger: introBlock.current,
                pin: true,
                start: "top top",
                end: "+=450",
                scrub: true,
                anticipatePin: 3
            }
        });
        pTimeline.from(introBlock.current, { y: 0, opacity: 1, duration: 2 });
        pTimeline.to(introBlock.current, { y: -300, opacity: 0, duration: 2 });

        return () => {
            ScrollTrigger.getById(ScrollTriggerIds.IntroScreenTrigger).kill();
        }
    }, []);

    return (
        <div data-mark="Genius Experience" className={` ${styles.intro}`} ref={introBlock}>
            <h1 className={styles.title}>
                The Genius Experiences
            </h1>
        </div>
    )
};
