import React, {FC, MouseEvent, useContext} from "react";
import styles from './Slider.module.scss';
import {useKeenSlider} from "keen-slider/react";
import {ArrowRight} from "./ArrowRight";
import {ArrowLeft} from "./ArrowLeft";
import "keen-slider/keen-slider.min.css"
import {designPipes} from "../../../models/static-data/design-pipes";
import {Circle} from "../circle";
import {AppContext} from "../../AppContainer";
import _ from 'lodash';

export const Slider: FC = () => {
    const {isMobile} = useContext(AppContext);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        loop: true
    });

    const {activeDesignPipe, chooseDesignPipe} = useContext(AppContext);
    const countOfItems = isMobile ? 5 : 8;

    return (
        <div className={styles.slider}>
            <div ref={sliderRef} className="keen-slider">
                {
                    _.chunk(designPipes, countOfItems).map((slide, i) => {
                            return (
                                <div className={`keen-slider__slide ${styles.slide}`} key={i}>
                                    {
                                        slide.map(pipe => {
                                            return (
                                                <Circle
                                                    key={pipe.id}
                                                    isActive={activeDesignPipe === pipe.id}
                                                    onClick={chooseDesignPipe}
                                                    background={pipe.image}
                                                    id={pipe.id}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                    )
                }
            </div>
            {slider && (
                <>
                    {/* @ts-ignore*/}
                    <ArrowLeft onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation() || slider.prev()}
                    />
                    {/*@ts-ignore*/}
                    <ArrowRight onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation() || slider.next()}
                    />
                </>
            )}
        </div>
    )
};
