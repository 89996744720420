
const initialState = {
    visibleFixedPipe: false,
    custopPipeValue1: "",
    custopPipeValue2: "",
    countLines: 1
}

function FixedCustomPipeReucer(state = initialState, action: any) {
    switch (action.type) {
        case "changeVisibleFixedPipe":
            return {
                ...state,
                visibleFixedPipe: action.payload
            }
        case "changeCustomValue1":
            return ({
                ...state,
                custopPipeValue1: action.payload
            })
        case "changeCustomValue2":
            return ({
                ...state,
                custopPipeValue2: action.payload
            })
        case "cahngeCountLines":
            return ({
                ...state,
                countLines: action.payload
            })
        default:
            return state
    }
}

export default FixedCustomPipeReucer