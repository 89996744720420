import {Slider} from "../../common/slider";
import {Link} from "../../common/link";
import styles from './CustomPipeDesign.module.scss';
import {useContext} from "react";
import {AppContext} from "../../AppContainer";

export const CustomPipeDesign = () => {
    const {openModal} = useContext(AppContext);

    return (
        <div>
            <Slider />
            <Link
                type={'button'}
                text={'Create your design'}
                mode={'white'}
                className={styles.link}
                onClick={openModal}
            />
        </div>
    )
};
