import React, { FC, useContext } from 'react';
import { kindPipe, SettingsPipe } from "../../models/settingsPipe";
import styles from "./CustomPipe.module.scss";
import { Button } from "../common/button";
import { colors } from "../../models/static-data/colors";
import { designPipes } from "../../models/static-data/design-pipes";
import { AppContext } from "../AppContainer";
import { Links } from '../../models/static-data/links';
import { sliders } from "../../models/static-data/sliders";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const CustomPipeAction: FC = () => {
    const {
        activeTypePipe,
        activeColorPipe,
        activeDesignPipe,
        activeSlider
    } = useContext(AppContext);

    const activeTab = useSelector((state: RootState) => state.CustomPipeExample.activeTab)


    switch (activeTab) {
        case SettingsPipe.Design:
            return (
                <div className={styles.action}>
                    <p className={styles.price}>from $119.95</p>
                    <Button
                        text={'Buy now'}
                        type={'link'}
                        link={designPipes.find(pipe => pipe.id === activeDesignPipe)?.link || Links.Shop}
                    />
                </div>
            );
        case SettingsPipe.Engraving:
            return (
                <div className={styles.action}>
                    <p className={styles.price}>from $99.95</p>
                    <Button text={'Buy now'} disabled={true} />
                </div>
            );
        case SettingsPipe.Slider:
            return (
                <div className={styles.action}>
                    <p className={styles.price}>from 24.95</p>
                    <Button
                        text={'Buy now'}
                        type={'link'}
                        link={sliders.find(slider => slider.id === activeSlider)?.link}
                    />
                </div>
            );
        default:
            return (
                <div className={styles.action}>
                    <p className={styles.price}>
                        from ${activeTypePipe === kindPipe.Standard ? '99.95' : '80.00'}
                    </p>
                    <Button
                        text={'Buy now'}
                        type={'link'}
                        link={colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.link}
                    />
                </div>
            )
    }
};
