import React from 'react';
import styles from './AboutPipe.module.scss'
import { Header } from '../../components/header'


function AboutPipe() {

    return (
        <>
            <Header />
        </>
    )
}

export default AboutPipe