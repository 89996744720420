import styles from './HeaderMobile.module.scss';
import React, {useContext} from "react";
import {leftNavigation, nav} from "../../../models/static-data/static-data";
import {Navigation} from "../../../models/navigation";
import {AppContext} from "../../AppContainer";
import {Close} from "../../common/close";

export const HeaderMobile = () => {
    const {setIsOpenMobileMenu} = useContext(AppContext);

    const closeMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>) => {
        if (e.currentTarget === e.target) {
            setIsOpenMobileMenu(false)
        }
    }

    return (
        <div
            className={styles.wrapper}
            onClick={closeMenu}
            onTouchStart={closeMenu}
        >
            <div className={styles.header}>
                <Close
                    onClick={() => setIsOpenMobileMenu(false)}
                    className={styles.close}
                />
                <nav>
                    <ul className={styles.nav}>
                        {
                            nav.map(({link, id, title}: Navigation) => (
                                <li key={id}>
                                    <a
                                        href={link}
                                        className={styles.link}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {title}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </nav>
                <ul className={styles.menu}>
                    {
                        leftNavigation.map(({link, id, icon, title}) => (
                            <li key={id}>
                                <a
                                    href={link}
                                    className={styles.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={icon} alt={title}/>
                                    {title}
                                </a>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>

    )
};
