import {useContext, useEffect} from 'react';
import styles from './CustomPipeColor.module.scss';
import {AppContext} from "../../AppContainer";
import {kindPipe} from "../../../models/settingsPipe";
import {CustomPipeColorBlock} from "./custom-pipe-color-block";

export const CustomPipeColor = () => {
    const {activeTypePipe, setActiveTypePipe} = useContext(AppContext);

    useEffect(() => {
        return () => {
            setActiveTypePipe(kindPipe.Standard);
        }
    }, []);

    return (
        <div className={styles.color}>
            <div className={styles.tabs}>
                <button
                    className={`${styles.tab} ${activeTypePipe === kindPipe.Standard ? styles.active : ''}`}
                    onClick={() => setActiveTypePipe(kindPipe.Standard)}
                >
                    Standard
                </button>
                <button
                    className={`${styles.tab} ${activeTypePipe === kindPipe.Mini ? styles.active : ''}`}
                    onClick={() => setActiveTypePipe(kindPipe.Mini)}
                >
                    Mini
                </button>
            </div>
            <CustomPipeColorBlock/>
        </div>
    )
};
