const initialState = {
    products: [],
    isLoaded: false
}

function catalogReducer(state = initialState, action: any) {
    switch (action.type) {
        case "setCatalogData":
            return {
                ...state,
                products: action.products
            }
        default:
            return state
    }
}

export default catalogReducer