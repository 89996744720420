import React, {FC} from "react";
import styles from './ReviewBlocks.module.scss';
import {ReviewsProps} from "../../../models/reviews";
import {ReviewBlock} from "../review-block";
import _ from "lodash";
import {reviewsData} from "../../../models/static-data/reviews-data";

export const ReviewBlocks: FC<ReviewsProps> = ({first, second, third}) => {
    const reviewsParsed = _.chunk(reviewsData, 2);

    return (
        <div className={styles.blocks}>
            <div className={styles.block} ref={first}>
                {
                    reviewsParsed[0].map(review => (
                        <ReviewBlock {...review} key={review.id}/>
                    ))
                }
            </div>
            <div className={styles.block} ref={second}>
                {
                    reviewsParsed[1].map(review => (
                        <ReviewBlock {...review} key={review.id}/>
                    ))
                }
            </div>
            <div className={styles.block} ref={third}>
                {
                    reviewsParsed[2].map(review => (
                        <ReviewBlock {...review} key={review.id}/>
                    ))
                }
            </div>
        </div>
    )
};
