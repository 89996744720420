import { FC } from 'react';
import styles from './CustomPipeAside.module.scss';
import { settingsCustomPipe } from "../../../models/static-data/static-data";
import { CustomPipeProps } from "../../../models/customPipeProps";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';

import { changeActiveTabAC } from '../../../redux/actions'

export const CustomPipeAside: FC = ({

}) => {
    const activeTab = useSelector((state: RootState) => state.CustomPipeExample.activeTab)
    const dispatch = useDispatch()
    return (
        <div className={styles.tabs}>
            {
                settingsCustomPipe.map(item => (
                    <p
                        key={item.id}
                        onClick={() => dispatch(changeActiveTabAC(item.id))}
                        className={`${styles.tab} ${activeTab === item.id ? styles.active : ''}`}
                    >
                        {item.text}
                    </p>
                ))
            }
        </div>
    )
};
