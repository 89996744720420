import { useContext, useEffect, useRef, useState } from 'react';
import { SettingsPipe } from "../../models/settingsPipe";
import styles from './CustomPipe.module.scss';
import { CustomPipeAside } from "./custom-pipe-aside";
import { Title } from "../common/title";
import { AppContext } from "../AppContainer";
import { CustomPipeContent } from "./CustomPipeContent";
import { CustomPipeExample } from "./CustomPipeExample";
import { ScrollTriggerIds } from "../../models/scrollTriggerIds";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CustomPipeAction } from "./CustomPipeAction";

gsap.registerPlugin(ScrollTrigger);


export const CustomPipe = () => {
    const { isMobile } = useContext(AppContext);
    const screen = useRef(null);


    useEffect(() => {
        gsap.timeline({
            scrollTrigger: {
                id: ScrollTriggerIds.CustomPipeTrigger,
                trigger: screen.current,
                start: "top top",
                end: "bottom bottom+=4000"
            }
        }).from(screen.current, { y: -50, opacity: 0 }).to(screen.current, { y: 0, opacity: 1 });
    }, []);

    return (
        <div className={styles.wrapper} ref={screen} style={{ height: '390vh', background: '#000' }}>
            <div className={styles.settings}>

                {isMobile && <Title className={styles.title}>Create Your Own Genius Pipe</Title>}
                <CustomPipeAside />

                <div className={styles.content}>

                    {!isMobile && <Title className={styles.title}>Create Your Own Genius Pipe</Title>}
                    <CustomPipeContent />
                    {isMobile && <CustomPipeExample />}
                    {!isMobile && <CustomPipeAction />}

                </div>

                {!isMobile && <CustomPipeExample />}
                {isMobile && <CustomPipeAction />}

            </div>

        </div>

    )
};
