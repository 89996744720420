import React from "react";
import styles from "./Slider.module.scss";
import {arrowRight} from "../../../assets/icons";

export const ArrowRight = (props: any) => {
    const disabled = props.disabled ? styles.disabled : "";

    return (
        <img
            onClick={props.onClick}
            className={`${styles.arrow} ${styles.right} ${disabled}`}
            src={arrowRight}
            alt=""
        />
    )
};
