import {logo} from "../../../assets/icons";
import {blackLogo} from "../../../assets/icons";
import React, {FC, useContext} from "react";
import {LogoProps} from "../../../models/logoProps";
import styles from './Logo.module.scss';
import {AppContext} from "../../AppContainer";

export const Logo: FC<LogoProps> = ({width, height, mode = ''}) => {
    const {isHeaderWhite} = useContext(AppContext);
    return (
        <a href="/">
            <img
                src={!mode && !isHeaderWhite ? logo : blackLogo}
                alt="Main"
                style={{width: `${width}px`, height: `${height}px`}}
                className={styles.logo}
            />
        </a>
    )
};
