import styles from './CustomPipeEngraving.module.scss';
import { Link } from "../../common/link";
import { useContext, useState } from "react";
import { fonts, lines } from '../../../models/static-data/engraving';
import { AppContext } from "../../AppContainer";

export const CustomPipeEngraving = () => {
    const { countEngravingLine, setCountEngravingLine, isMobile } = useContext(AppContext);
    const [fontFamily, setFontFamily] = useState('Georgia');

    return (
        <div className={styles.engraving}>

        </div>
    )
};
